<template>
  <div
    :class="context.classes.element"
    :data-type="context.type"
    style="position: relative;"
  >
    <FormulateSlot
      name="prefix"
      :context="context"
    >
      <component
        :is="context.slotComponents.prefix"
        v-if="context.slotComponents.prefix"
        :context="context"
      />
    </FormulateSlot>
    <c-box
      position="absolute"
      :left="['17px', '23px']"
      top="50%"
      transform="translateY(-50%)"
    >
      <img
        :style="[{height: '20px', color: 'red'}, attributes?.['icon-prefix-style'] ? attributes?.['icon-prefix-style']: {}]"
        :src="attributes?.['icon-prefix']"
        alt="icon prefix"
      >
    </c-box>
    <input
      id="tessssf"
      ref="dateRef"
      v-model="context.model"
      :type="type"
      :class="{
        default: true,
        err: context.validationErrors.length > 0 && context.showValidationErrors,
      }"
      style="opacity: 0"
      @blur="context.blurHandler"
      @change="onChange($event)"
      v-on="$listeners"
    >
    <label
      for="tessssf"
      :class="{
        default: true,
        err: context.validationErrors.length > 0 && context.showValidationErrors,
      }"
      style="position: absolute; top: 0; left: 0; cursor: pointer"
      @click="$refs.dateRef.showPicker()"
    >
      {{ formatDateV2(value || context.model) || context.attributes.placeholder }}
    </label>
    <FormulateSlot
      name="suffix"
      :context="context"
    >
      <component
        :is="context.slotComponents.suffix"
        v-if="context.slotComponents.suffix"
        :context="context"
      />
    </FormulateSlot>
  </div>
</template>

<script>
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin'
import { formatDateV2 } from '@/utils/format-date'
export default {
  mixins: [FormulateInputMixin],
  data() {
    return {
      value: false,
    }
  },
  methods: {
    formatDateV2,
    onChange(event) {
      this.value = event.target.value
    },
  },
}
</script>

<style scoped>
.default {
  font-size: 14px;
  border-radius: 8px;
  width: 100%;
  border: 1px solid #888888;
  padding: 12px 14px 12px 45px;
  height: 45px;
}

.err {
  border: 1px solid red;
}

.default:focus-visible {
  border: 1px solid #008c81;
}

@media (min-width: 640px) {
  .default {
    padding: 12px 14px 12px 54px;
  }
} 
</style>
