import Vue from 'vue'
export const namespaced = true

export const state = {
}

export const getters = {
  getData: (state) => state,
}

export const mutations = {
  setData(state, { key, props, data }) {
    if (!state[key]) {
      Vue.set(state, key, {
        isLoading: false,
        isError: null,
        isSuccess: null,
        data: null,
      })
    }
    if (!state[key][props]) {
      Vue.set(state[key], props, data)
    }
    state[key][props] = data
  },
  reset(state, { key }) {
    if (!state[key]) return
    Vue.delete(state, key)
  },
}