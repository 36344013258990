export const namespaced = true

export const state = {
  items: [],
  item: {},
  meta: {},
}

export const getters = {
  items: (state) => state.items,
  item: (state) => state.item,
  meta: (state) => state.meta,
}

export const mutations = {
  setItems(state, items) {
    state.items = items
  },
  setItem(state, item) {
    state.item = item
  },
  setMeta(state, meta) {
    state.meta = meta
  },
}

export const actions = {
  async listAdminsSuperadmin(ctx, params) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/super-admin/admin', { params: params })
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setItems', it.data)
        ctx.commit('setMeta', it.meta)
      })
      .catch(() => {
        ctx.commit('setItems', [])
        ctx.commit('setMeta', 1)
      })
  },
  createAdminsSuperadmin(ctx, data) {
    let axios = ctx.rootGetters.axios
    return axios
      .post('/v1/super-admin/admin', data)
      .then((it) => it.data.data)
      .then((it) => {
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  detailAdminsSuperadmin(ctx, id) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/super-admin/admin/${id}`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setItem', it)
      })
      .catch((err) => {
        throw err.response
      })
  },
  deleteAdminsSuperadmin(ctx, id) {
    let axios = ctx.rootGetters.axios
    return axios
      .delete(`/v1/super-admin/admin/${id}`)
      .then((it) => it.data.data)
      .catch((err) => {
        throw err.response
      })
  },
  updateAdminsSuperadmin(ctx, data) {
    let axios = ctx.rootGetters.axios
    return axios
      .put(`/v1/super-admin/admin/${data.id}`, data)
      .then((it) => it.data.data)
      .catch((err) => {
        throw err.response
      })
  },
}
