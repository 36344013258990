export default function getMenuCategory(menu) {
  switch (menu) {
    case 'morningSnack':
      return 'Snack pagi'
    case 'afternoonSnack':
      return 'Snack siang'
    case 'lunch':
      return 'Makan siang'
    case 'dinner':
      return 'Makan malam'
    case 'nightSnack':
      return 'Snack malam'
    case 'breakfast':
    default:
      return 'Sarapan'
  }
}
