export const namespaced = true

export const state = {
  data: {},
}

export const getters = {
  hasItem: (state) => (key) => {
    return state.data[key] != null
  },
  getItem: (state) => (key, defaultValue = null) => {
    return state.data[key] ?? defaultValue
  },
}
export const mutations = {
  setItem(state, { key, value }) {
    state.data[key] = value
  },
}
