var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticStyle: {
      "font-size": "14px",
      "margin-left": "10px"
    },
    attrs: {
      "for": _vm.context.id
    }
  }, [_vm._v(" " + _vm._s(_vm.context.label) + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }