export let namespaced = true

export let state = {}

export let mutations = {}

export let actions = {
  list(ctx, { q, foodGroup, char, limit = 100 } = {}) {
    let axios = ctx.rootGetters.axios
    let params = new URLSearchParams()

    if (q != null) params.set('q', q)
    if (foodGroup != null) params.set('foodGroup', foodGroup)
    if (char != null) params.set('char', char)
    params.set('perpage', limit)

    return axios
      .get(`/v1/admin/meal-glossaries?${params.toString()}`)
      .then((r) => r.data.data)
  },
  detail(ctx, glossaryId) {
    let axios = ctx.rootGetters.axios

    return axios
      .get(`/v1/admin/meal-glossaries/${glossaryId}`)
      .then((r) => r.data.data)
  },
  update(ctx, data) {
    let axios = ctx.rootGetters.axios
    let glossaryId = data.id

    return axios
      .put(`/v1/admin/meal-glossaries/${glossaryId}`, data)
      .then((r) => r.data.data)
  },
  create(ctx, data) {
    let axios = ctx.rootGetters.axios
    return axios
      .post('/v1/admin/meal-glossaries', data)
      .then((r) => r.data.data)
  },
  upload(ctx, file) {
    let axios = ctx.rootGetters.axios
    let formData = new FormData()
    formData.set('file', file)

    return axios
      .post('/v1/admin/meal-glossaries/upload', formData)
      .then((r) => r.data.data.url)
  },
  delete(ctx, glossaryId) {
    let axios = ctx.rootGetters.axios
    return axios
      .delete(`/v1/admin/meal-glossaries/${glossaryId}`)
      .then((r) => r.data.data)
  },
}
