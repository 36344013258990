export const namespaced = true
export const state = {
  clients: [],
  clientMeta: {},
  nutritionists: [],
  nutritionistMeta: {},
  payments: [],
  paymentMeta: {},
  coupons: [],
  couponMeta: {},
  demographic: [],
  demographicMeta: {},
}
export const mutations = {
  setClients(state, clients) {
    state.clients = clients
  },
  setClientMeta(state, meta) {
    state.clientMeta = meta
  },
  setNutritionists(state, nutritionists) {
    state.nutritionists = nutritionists
  },
  setNutritionistMeta(state, meta) {
    state.nutritionistMeta = meta
  },
  setPayments(state, payments) {
    state.payments = payments
  },
  setPaymentMeta(state, meta) {
    state.paymentMeta = meta
  },
  setCoupons(state, coupons) {
    state.coupons = coupons
  },
  setCouponMeta(state, meta) {
    state.couponMeta = meta
  },
  setDemographic(state, demographic) {
    state.demographic = demographic
  },
  setDemographicMeta(state, meta) {
    state.demographicMeta = meta
  },
}
export const actions = {
  async listClientsTracking(ctx, params) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/super-admin/tracking/clients', { params: params })
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setClients', it.data)
        ctx.commit('setClientMeta', it.meta)
      })
      .catch(() => {
        ctx.commit('setClients', [])
        ctx.commit('setClientMeta', 1)
      })
  },
  async listNutritionistsTracking(ctx, params) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/super-admin/tracking/nutritionists', { params: params })
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setNutritionists', it.data)
        ctx.commit('setNutritionistMeta', it.meta)
      })
      .catch(() => {
        ctx.commit('setNutritionists', [])
        ctx.commit('setNutritionistMeta', 1)
      })
  },
  async listPaymentsTracking(ctx, params) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/super-admin/tracking/orders', { params: params })
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setPayments', it.data)
        ctx.commit('setPaymentMeta', it.meta)
      })
      .catch(() => {
        ctx.commit('setPayments', [])
        ctx.commit('setPaymentMeta', 1)
      })
  },
  async listCouponsTracking(ctx, params) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/super-admin/tracking/coupons', { params: params })
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setCoupons', it.data)
        ctx.commit('setCouponMeta', it.meta)
      })
      .catch(() => {
        ctx.commit('setCoupons', [])
        ctx.commit('setCouponMeta', 1)
      })
  },
  async listDemographicAnalytic(ctx, params) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/super-admin/tracking/demographic-analytic', { params: params })
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setDemographic', it.data)
        ctx.commit('setDemographicMeta', it.meta)
      })
      .catch(() => {
        ctx.commit('setDemographic', [])
        ctx.commit('setDemographicMeta', 1)
      })
  },
}
