var render = function () {
  var _vm$attributes, _vm$attributes2, _vm$attributes3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.context.classes.element,
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "data-type": _vm.context.type
    }
  }, [_c('FormulateSlot', {
    attrs: {
      "name": "prefix",
      "context": _vm.context
    }
  }, [_vm.context.slotComponents.prefix ? _c(_vm.context.slotComponents.prefix, {
    tag: "component",
    attrs: {
      "context": _vm.context
    }
  }) : _vm._e()], 1), _c('c-box', {
    attrs: {
      "position": "absolute",
      "left": ['17px', '23px'],
      "top": "50%",
      "transform": "translateY(-50%)"
    }
  }, [_c('img', {
    style: [{
      height: '20px',
      color: 'red'
    }, (_vm$attributes = _vm.attributes) !== null && _vm$attributes !== void 0 && _vm$attributes['icon-prefix-style'] ? (_vm$attributes2 = _vm.attributes) === null || _vm$attributes2 === void 0 ? void 0 : _vm$attributes2['icon-prefix-style'] : {}],
    attrs: {
      "src": (_vm$attributes3 = _vm.attributes) === null || _vm$attributes3 === void 0 ? void 0 : _vm$attributes3['icon-prefix'],
      "alt": "icon prefix"
    }
  })]), _vm.type === 'checkbox' ? _c('input', _vm._g(_vm._b({
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.context.model,
      expression: "context.model"
    }],
    class: {
      default: true,
      err: _vm.context.validationErrors.length > 0 && _vm.context.showValidationErrors
    },
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.context.model) ? _vm._i(_vm.context.model, null) > -1 : _vm.context.model
    },
    on: {
      "blur": _vm.context.blurHandler,
      "change": function change($event) {
        var $$a = _vm.context.model,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.context, "model", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.context, "model", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.context, "model", $$c);
        }
      }
    }
  }, 'input', _vm.attributes, false), _vm.$listeners)) : _vm.type === 'radio' ? _c('input', _vm._g(_vm._b({
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.context.model,
      expression: "context.model"
    }],
    class: {
      default: true,
      err: _vm.context.validationErrors.length > 0 && _vm.context.showValidationErrors
    },
    attrs: {
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.context.model, null)
    },
    on: {
      "blur": _vm.context.blurHandler,
      "change": function change($event) {
        return _vm.$set(_vm.context, "model", null);
      }
    }
  }, 'input', _vm.attributes, false), _vm.$listeners)) : _c('input', _vm._g(_vm._b({
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.context.model,
      expression: "context.model"
    }],
    class: {
      default: true,
      err: _vm.context.validationErrors.length > 0 && _vm.context.showValidationErrors
    },
    attrs: {
      "type": _vm.type
    },
    domProps: {
      "value": _vm.context.model
    },
    on: {
      "blur": _vm.context.blurHandler,
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.context, "model", $event.target.value);
      }
    }
  }, 'input', _vm.attributes, false), _vm.$listeners)), _c('FormulateSlot', {
    attrs: {
      "name": "suffix",
      "context": _vm.context
    }
  }, [_vm.context.slotComponents.suffix ? _c(_vm.context.slotComponents.suffix, {
    tag: "component",
    attrs: {
      "context": _vm.context
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }