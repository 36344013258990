export const namespaced = true

export const state = {
  data: [],
}

export const getters = {
  data: state => state.data,
}

export const mutations = {
  setData(state, data) {
    state.data = data
  },
}

export const actions = {
  /** 
    * @returns {Promise<{
    *     status: boolean,
    *     message: string,
    *     data: {
    *         categories: string,
    *         list: {
    *           id: string,
    *           list: string,
    *           order: number,
    *           url: string,
    *         }[],
    *         order: number,
    *     },
    *     meta: {}
    * }>}
  */
  getFooter(ctx) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/general/footer')
      .then((it) => {
        ctx.commit('setData', it.data.data)
      })
  }, 
}
