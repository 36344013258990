export const namespaced = true

export const state = {
  paymentMethods: [],
  transaction: {},
}

export const getters = {
  paymentMethods: state => state.paymentMethods,
  transaction: state => state.transaction,
}

export const mutations = {
  setPaymentMethods(state, items) {
    state.paymentMethods = items
  },
  setTransaction(state, item) {
    state.transaction = item
  },
}

export const actions = {
  getPaymentMethods(ctx) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/payment/payment-methods')
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setPaymentMethods', it)
      })
  },
  getPaymentInstruction(ctx, paymentMethodId) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/payment/payment-instructions/${paymentMethodId}`)
      .then((it) => it.data)
      .then((it) => {
        return it
      })
  },
  /**
   * @param {string} transactionId
   *
   * @returns { Promise<{
   *     status: boolean,
   *     message: string,
   *     data: {
   *         id: string,
   *         cartId: string,
   *         orderId: string,
   *         clientId: string,
   *         productServiceId: string,
   *         couponId: string,
   *         couponName: string,
   *         total: number,
   *         subTotal: number,
   *         duration: number,
   *         invoiceNumber: string,
   *         fraudStatus: string,
   *         transactionStatus: ("pending"),
   *         status: ("pending" | "done" | "failed"),
   *         createdAt: "2023-05-08 19:07:10",
   *         totalDiscount: number,
   *         paymentMethodId: null,
   *         paymentMethodCode: string,
   *         paymentMethodName: string,
   *         billerCode: string,
   *         vaNumber: string,
   *         qrCodeLink: string,
   *         deepLink: string,
   *         productPrice: number,
   *         productName: string,
   *         productServiceName: string,
   *         productPhoto: string,
   *         quantity: number,
   *         expiryAt: string,
   *         programId: string,
   *         transactionTime: string,
   *         transactionId: string,
   *         redirectUrl: string,
   *         isSentReminder: number,
   *         ezpayWebDeepLink: string,
   *         ezpayMobileDeepLink: string,
   *         flagRoute: null
   *     },
   *     meta: {}
   * }> }
   */
  getTransaction(ctx, transactionId) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/clients/transaction/${transactionId}`)
      .then((it) => {
        ctx.commit('setTransaction', it.data.data)
        return it.data
      })
      .catch((err) => {
        throw err.response
      })
  },
  createMidtransCharge(ctx, payload) {
    let axios = ctx.rootGetters.axios
    return axios
      .post('/v1/payment/midtrans-charge', payload)
      .then((it) => {
        ctx.commit('setTransaction', it.data.data)
        return it.data.data
      })
      .catch((err) => {
        throw err.response
      })
  },
  updateMidtransCharge(ctx, payload) {
    let axios = ctx.rootGetters.axios
    return axios
      .post('/v1/payment/midtrans-update', payload)
      .then((it) => {
        ctx.commit('setTransaction', it.data.data)
        return it.data.data
      }) 
      .catch((err) => {
        throw err.response
      })
  },
  midtransCancel(ctx, payload) {
    const payload_ = {
      transactionId: payload.transactionId,
    }
    let axios = ctx.rootGetters.axios
    return axios
      .post('/v1/payment/midtrans-cancel', payload_)
      .then((it) => {
        // ctx.commit('setTransaction', it.data.data)
        return it.data.data
      })
      .catch((err) => {
        throw err.response
      })
  },
  downloadInvoice(ctx, transactionId) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/payment/download-invoice/${transactionId}`, {
        responseType: 'blob',
        headers: {
          'Content-type': 'application/pdf',
        },
      })
      .then((it) => {
        return it.data
      })
      .catch((err) => {
        throw err.response
      })
  },
}
