import posthog from 'posthog-js'
import { ENV } from '@/constants/config'

export default {
  install(Vue) {
    Vue.prototype.$posthog = posthog.init(
      ENV.POSTHOG_PROJECT_API_KEY,
      {
        api_host: ENV.POSTHOG_INSTANCE_ADDRESS,
      },
    )
  },
}