export const namespaced = true

export const state = {
  items: [],
  item: {},
  meta: {},
}

export const getters = {
  items: (state) => state.items,
  item: (state) => state.item,
  meta: (state) => state.meta,
}

export const mutations = {
  setItems(state, items) {
    state.items = items
  },
  setItem(state, item) {
    state.item = item
  },
  setMeta(state, meta) {
    state.meta = meta
  },
}

export const actions = {
  async listLogActivity(ctx, params) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/admin/log-activity', { params: params })
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setItems', it.data)
        ctx.commit('setMeta', it.meta)
      })
      .catch(() => {
        ctx.commit('setItems', [])
        ctx.commit('setMeta', 1)
      })
  },
}
