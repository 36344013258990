var render = function () {
  var _vm$attributes, _vm$attributes2, _vm$attributes3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.context.classes.element,
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "data-type": _vm.context.type,
      "data-multiple": _vm.attributes && _vm.attributes.multiple !== undefined
    }
  }, [_c('FormulateSlot', {
    attrs: {
      "name": "prefix",
      "context": _vm.context
    }
  }, [_vm.context.slotComponents.prefix ? _c(_vm.context.slotComponents.prefix, {
    tag: "component",
    attrs: {
      "context": _vm.context
    }
  }) : _vm._e()], 1), _c('c-box', {
    attrs: {
      "position": "absolute",
      "left": ['17px', '23px'],
      "top": "50%",
      "transform": "translateY(-50%)"
    }
  }, [_c('img', {
    style: [{
      height: '20px',
      color: 'red'
    }, (_vm$attributes = _vm.attributes) !== null && _vm$attributes !== void 0 && _vm$attributes['icon-prefix-style'] ? (_vm$attributes2 = _vm.attributes) === null || _vm$attributes2 === void 0 ? void 0 : _vm$attributes2['icon-prefix-style'] : {}],
    attrs: {
      "src": (_vm$attributes3 = _vm.attributes) === null || _vm$attributes3 === void 0 ? void 0 : _vm$attributes3['icon-prefix'],
      "alt": "icon prefix"
    }
  })]), _c('label', {
    class: {
      default: true,
      err: _vm.context.validationErrors.length > 0 && _vm.context.showValidationErrors
    },
    staticStyle: {
      "position": "absolute",
      "height": "100%",
      "display": "flex",
      "align-items": "center"
    },
    attrs: {
      "for": "custom-select"
    }
  }, [_vm._v(" " + _vm._s(_vm.value || _vm.context.model || _vm.context.placeholder) + " ")]), _c('select', _vm._g(_vm._b({
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.context.model,
      expression: "context.model"
    }],
    class: {
      default: true,
      err: _vm.context.validationErrors.length > 0 && _vm.context.showValidationErrors
    },
    staticStyle: {
      "opacity": "0",
      "cursor": "pointer"
    },
    attrs: {
      "id": "custom-select",
      "data-placeholder-selected": _vm.placeholderSelected
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.context, "model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.onChange($event);
      }],
      "blur": _vm.context.blurHandler
    }
  }, 'select', _vm.attributes, false), _vm.$listeners), [_vm.context.placeholder ? _c('option', {
    attrs: {
      "value": "",
      "hidden": "hidden",
      "disabled": ""
    },
    domProps: {
      "selected": !_vm.hasValue
    }
  }, [_vm._v(" " + _vm._s(_vm.context.placeholder) + " ")]) : _vm._e(), !_vm.optionGroups ? _vm._l(_vm.options, function (option) {
    return _c('option', _vm._b({
      key: option.id,
      attrs: {
        "disabled": !!option.disabled
      },
      domProps: {
        "value": option.value,
        "textContent": _vm._s(option.label)
      }
    }, 'option', option.attributes || option.attrs || {}, false));
  }) : _vm._l(_vm.optionGroups, function (subOptions, label) {
    return _c('optgroup', {
      key: label,
      attrs: {
        "label": label
      }
    }, _vm._l(subOptions, function (option) {
      return _c('option', _vm._b({
        key: option.id,
        attrs: {
          "disabled": !!option.disabled
        },
        domProps: {
          "value": option.value,
          "textContent": _vm._s(option.label)
        }
      }, 'option', option.attributes || option.attrs || {}, false));
    }), 0);
  })], 2), _c('FormulateSlot', {
    attrs: {
      "name": "suffix",
      "context": _vm.context
    }
  }, [_vm.context.slotComponents.suffix ? _c(_vm.context.slotComponents.suffix, {
    tag: "component",
    attrs: {
      "context": _vm.context
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }