import { reqReferral } from '@/requests/dietela-api/referral'

export const namespaced = true

export const state = {
  referral: {
    totalClientUse: 0,
    infoClientUse: [],
  },
  faqs: [],
  poin: 0,
}

export const getters = {
  referral: (state) => {
    return state?.referral
  },
  faqs: (state) => {
    return state.faqs
  },
  poin: (state) => {
    return state.poin
  },
}
export const mutations = {
  setReferral(state, data) {
    state.referral = data
  },
  setFaqs(state, data) {
    state.faqs = data
  },
  setPoin(state, data) {
    state.poin = data
  },
}

export const actions = {
  getReferral(ctx) {
    let axios = ctx.rootGetters.axios
    return reqReferral.get(axios)
      .then((it) => {
        ctx.commit('setReferral', it.data?.data)
      })
      .catch((e) => {
        throw new Error(e.response.data.message || 'Something went wrong!')
      })
  },
  getFaqs(ctx) {
    let axios = ctx.rootGetters.axios
    return reqReferral.faqs(axios)
      .then((it) => {
        ctx.commit('setFaqs', it.data?.data)
      })
      .catch((e) => {
        throw new Error(e.response.data.message || 'Something went wrong!')
      })
  },
  getPoin(ctx) {
    let axios = ctx.rootGetters.axios
    return reqReferral.poin(axios)
      .then((it) => {
        ctx.commit('setPoin', it.data?.data?.poin)
      })
      .catch((e) => {
        throw new Error(e.response.data.message || 'Something went wrong!')
      })
  },
}
