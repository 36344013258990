export const namespaced = true
export const state = {
  items: [
    {
      id: 0,
      title: 'Meal plan mu sudah tersedia!',
      body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, ab asperiores corporis cumque debitis delectus eius facere ipsam molestias, officia optio pariatur possimus quis saepe voluptatibus. Blanditiis deleniti iste quis!',
      unread: true,
      date: '2021-11-16T08:09:44.384Z',
    },
    {
      id: 1,
      title: 'Meal plan mu sudah tersedia!',
      body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, ab asperiores corporis cumque debitis delectus eius facere ipsam molestias, officia optio pariatur possimus quis saepe voluptatibus. Blanditiis deleniti iste quis!',
      unread: false,
      date: '2021-11-15T08:09:44.384Z',
    },
    {
      id: 2,
      title: 'Meal plan mu sudah tersedia!',
      body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, ab asperiores corporis cumque debitis delectus eius facere ipsam molestias, officia optio pariatur possimus quis saepe voluptatibus. Blanditiis deleniti iste quis!',
      unread: false,
      date: '2021-11-10T08:09:44.384Z',
    },
    {
      id: 3,
      title: 'Meal plan mu sudah tersedia!',
      body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, ab asperiores corporis cumque debitis delectus eius facere ipsam molestias, officia optio pariatur possimus quis saepe voluptatibus. Blanditiis deleniti iste quis!',
      unread: false,
      date: '2021-11-10T08:09:44.384Z',
    },
    {
      id: 4,
      title: 'Meal plan mu sudah tersedia!',
      body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, ab asperiores corporis cumque debitis delectus eius facere ipsam molestias, officia optio pariatur possimus quis saepe voluptatibus. Blanditiis deleniti iste quis!',
      unread: false,
      date: '2021-11-10T08:09:44.384Z',
    },
  ],
}
export const getters = {
  isEmpty: (state) => state.items.length === 0,
}
export const mutations = {}
export const actions = {}
