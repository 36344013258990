const ENV = {
  // GENERAL
  URL_DIETELA_LANDING_PAGE: process.env.VUE_APP_URL_DIETELA_LANDING_PAGE,
  URL_DIETELA_WORDPRESS: process.env.VUE_APP_URL_DIETELA_WORDPRESS,
  URL_DIETELA_CMS: process.env.VUE_APP_URL_DIETELA_CMS,
  URL_DIETELA_NAMA: process.env.VUE_APP_URL_DIETELA_NAMA,
  URL_DIETELA_FE: process.env.VUE_APP_URL_DIETELA_FE,
  URL_DIETELA_BE: process.env.VUE_APP_URL_DIETELA_BE,

  // MIDTRANS
  MIDTRANS_DATA_ENVIRONMENT: process.env.VUE_APP_MIDTRANS_DATA_ENVIRONMENT,
  MIDTRANS_DATA_CLIENT_KEY: process.env.VUE_APP_MIDTRANS_DATA_CLIENT_KEY,

  // POSTHOG
  POSTHOG_PROJECT_API_KEY: process.env.VUE_APP_POSTHOG_PROJECT_API_KEY,
  POSTHOG_INSTANCE_ADDRESS: process.env.VUE_APP_POSTHOG_INSTANCE_ADDRESS,
}

module.exports = { ENV }
