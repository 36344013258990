import Vue from 'vue'

export const namespaced = true
export const state = {
  // 0
  informedConsent: {
    isAgreeForResearch: null,
    isAgreeInformedConsent: null,
  },
  // 1
  generalData: {
    firstName: null,
    middleName: null,
    lastName: null,
    gender: null,
    age: null,
    birthDate: null,
    address: null,
    city: null,
    province: null,
    cities: null,
    zipCode: null,
    phone: null,
    mainTarget: null,
    expectedDiet: null,
    hasScales: null,
    currentCondition: null,
  },
  // 2
  anthropometryData: {
    weight: null,
    height: null,
    waistSize: null,
    fat: null,
    muscleMass: null,
    bodyWater: null,
    visceralFat: null,
    boneMass: null,
    basalMetabolicRate: null,
  },
  // 3
  dietAndEatingHistory: {
    foodPreference: null,
    mainFoodConsumption: null,
    snackConsumption: null,
    foodSchedule: null,
    foodConsumptionType: null,
    dislikeFood: null,
    favoriteFood: null,
    expectedBreakfast: null,
    expectedLunchDinner: null,
    foodAllergies: null,
    fluidConsumption: null,
    vegetablesConsumption: null,
    fruitConsumption: null,
    beveragesComsumption: null,
    friedSnackConsumption: null,
    saltySnackConsumption: null,
    sweetSnackConsumption: null,
    personServeFood: null,
    milkFoodAndDrinkConsumption: null,
    lifestyle: null,
    // breakfastHabits: null,
    // breakfastFoodType: null,
  },
  // 4
  dailyActivities: {
    sportType: null,
    weekdaysTypicalActivity: null,
    weekendTypicalActivity: null,
    reducePhysicalActivity: null,
    physicalLimitationActivity: null,
    averageSleepHours: null,
    bedTime: null,
    // activeLevel: null,
  },
  // 5
  foodRecords: {},
  // 6
  physicalActivityAbilities: {
    hasDoctorDiagnose: null,
    hasChestPain: null,
    hasLostBalance: null,
    hasBoneProblem: null,
    isOnTreatment: null,
    hasOtherCondition: null,
  },
  // 7
  medicalHistory: {
    job: null,
    lastEducation: null,
    drugsConsumption: null,
    specialMedicine: null,
    supplementConsumption: null,
    otherDisease: null,
    communicationIntensity: null,
    expectNutritionist: null,
    expectProgram: null,
    mentalDisorder: null,
    disease: null,
    symptom: null,
    currentBehavior: null,
  },
  // ---
  questions: [],
  currentStep: 0,
}

export const getters = {
  questionFor(state) {
    return (category) => {
      return state.questions.filter((it) => it.category === category)
    }
  },
}

export const mutations = {
  setInformedConsent(state, payload) {
    state.informedConsent = payload
  },
  setGeneralData(state, payload) {
    state.generalData = payload
  },
  setAnthropometryData(state, payload) {
    state.anthropometryData = payload
  },
  setDietAndEatingHistory(state, payload) {
    if (payload) {
      state.dietAndEatingHistory = payload
    }
  },
  setDailyActivities(state, payload) {
    if (payload) {
      state.dailyActivities = payload
    }
  },
  setPhysicalActivityAbilities(state, payload) {
    state.physicalActivityAbilities = payload
  },
  setMedicalHistory(state, payload) {
    state.medicalHistory = payload
  },
  setFoodRecords(state, payload) {
    state.foodRecords = payload
  },
  setQuestion(state, question) {
    let index = state.questions.findIndex((it) => it.id === question.id)

    if (index !== -1) {
      Vue.set(state.questions, index, question)
    } else {
      state.questions.push(question)
    }
  },
  setAnswer(state, answer) {
    let index = state.questions.findIndex((it) => it.id === answer.questionId)

    if (index !== -1) {
      Vue.set(state.questions, index, {
        ...state.questions[index],
        answer: answer.answer,
      })
    }
  },
  setCurrentStep(state, step) {
    state.currentStep = step
  },

  // Formulir Gizi Step 3
  setFoodPreference(state, value) {
    state.dietAndEatingHistory.foodPreference = value
  },
  setMainFoodConsumption(state, value) {
    state.dietAndEatingHistory.mainFoodConsumption = value
  },
  setSnackConsumption(state, value) {
    state.dietAndEatingHistory.snackConsumption = value
  },
  setFoodSchedule(state, value) {
    state.dietAndEatingHistory.foodSchedule = value
  },
  setFoodConsumptionType(state, value) {
    state.dietAndEatingHistory.foodConsumptionType = value
  },
  setDislikeFood(state, value) {
    state.dietAndEatingHistory.dislikeFood = value
  },
  setFavoriteFood(state, value) {
    state.dietAndEatingHistory.favoriteFood = value
  },
  setExpectedBreakfast(state, value) {
    state.dietAndEatingHistory.expectedBreakfast = value
  },
  setExpectedLunchDinner(state, value) {
    state.dietAndEatingHistory.expectedLunchDinner = value
  },
  setFoodAllergies(state, value) {
    state.dietAndEatingHistory.foodAllergies = value
  },
  setFluidConsumption(state, value) {
    state.dietAndEatingHistory.fluidConsumption = value
  },
  setVegetablesConsumption(state, value) {
    state.dietAndEatingHistory.vegetablesConsumption = value
  },
  setFruitConsumption(state, value) {
    state.dietAndEatingHistory.fruitConsumption = value
  },
  setBeveragesComsumption(state, value) {
    state.dietAndEatingHistory.beveragesComsumption = value
  },
  setFriedSnackConsumption(state, value) {
    state.dietAndEatingHistory.friedSnackConsumption = value
  },
  setSaltySnackConsumption(state, value) {
    state.dietAndEatingHistory.saltySnackConsumption = value
  },
  setSweetSnackConsumption(state, value) {
    state.dietAndEatingHistory.sweetSnackConsumption = value
  },
  setPersonServeFood(state, value) {
    state.dietAndEatingHistory.personServeFood = value
  },
  setMilkFoodAndDrinkConsumption(state, value) {
    state.dietAndEatingHistory.milkFoodAndDrinkConsumption = value
  },
  setLifestyle(state, value) {
    state.dietAndEatingHistory.lifestyle = value
  },
  resetDietAndEatingHistory(state) {
    state.dietAndEatingHistory = {}
  },
  resetInformedConsent(state) {
    state.informedConsent = {}
  },
  resetGeneralData(state) {
    state.generalData = {}
  },
  resetAnthropometryData(state) {
    state.anthropometryData = {}
  },
  resetFoodRecords(state) {
    state.foodRecords = {}
  },
  resetMedicalHistory(state) {
    state.medicalHistory = {}
  },
  setSportType(state, value) {
    state.dailyActivities.sportType = value
  },
  setReducePhysicalActivity(state, value) {
    state.dailyActivities.reducePhysicalActivity = value
  },
  setPhysicalLimitationActivity(state, value) {
    state.dailyActivities.physicalLimitationActivity = value
  },
  setWeekdaysTypicalActivity(state, value) {
    state.dailyActivities.weekdaysTypicalActivity = value
  },
  setWeekendTypicalActivity(state, value) {
    state.dailyActivities.weekendTypicalActivity = value
  },
  setBedTime(state, value) {
    state.dailyActivities.bedTime = value
  },
  setAverageSleepHours(state, value) {
    state.dailyActivities.averageSleepHours = value
  },
  resetDailyActivities(state) {
    state.dailyActivities = {}
  },
  resetKuisioner(state) {
    state.anthropometryData = {}
    state.dailyActivities = {}
    state.dietAndEatingHistory = {}
    state.foodRecords = {}
    state.generalData = {}
    state.informedConsent = {}
    state.medicalHistory = {}
    state.physicalActivityAbilities = {}
    state.questions = {}
    state.currentStep = 0
  },
}
export const actions = {
  // Draft / save to backend temporarily
  async setInformedConsent({ commit, rootGetters }, payload) {
    let axios = rootGetters.axios
    let { programId, answers, value, step = 1 } = payload

    commit('setInformedConsent', value)
    for (let answer of answers) {
      commit('setAnswer', answer)
    }

    return axios.post(`/v1/clients/questionnaire/${programId}`, {
      isDraft: true,
      answers,
      informedConsent: value,
      step,
    }).then((r) => {
      return r.data.data
    })
  },
  async setGeneralData({ commit, rootGetters }, payload) {
    let axios = rootGetters.axios
    let { programId, answers, value, step = 2 } = payload

    commit('setGeneralData', value)
    for (let answer of answers) {
      commit('setAnswer', answer)
    }

    return axios
      .post(`/v1/clients/questionnaire/${programId}`, {
        isDraft: true,
        answers,
        generalData: value,
        step,
      })
      .then((r) => r.data.data)
  },
  async setAnthropometryData({ commit, rootGetters }, payload) {
    let axios = rootGetters.axios
    let { programId, answers, value, step = 3 } = payload

    commit('setAnthropometryData', value)
    for (let answer of answers) {
      commit('setAnswer', answer)
    }

    return axios
      .post(`/v1/clients/questionnaire/${programId}`, {
        isDraft: true,
        answers: answers,
        anthropometryData: value,
        step,
      })
      .then((r) => r.data.data)
  },
  async setDietAndEatingHistory({ rootGetters }, payload) {
    let axios = rootGetters.axios
    let { programId, value, answers, step = 4 } = payload

    // commit('setDietAndEatingHistory', value)
    // for (let answer of answers) {
    //   commit('setAnswer', answer)
    // }

    return axios
      .post(`/v1/clients/questionnaire/${programId}`, {
        isDraft: true,
        answers: answers,
        dietAndEatingHistory: value,
        step,
      })
      .then((r) => r.data.data)
  },
  async setDailyActivities({ rootGetters }, payload) {
    let axios = rootGetters.axios
    let { programId, value, answers, isDraft, step = 5 } = payload

    // commit('setDailyActivities', value)
    // for (let answer of answers) {
    //   commit('setAnswer', answer)
    // }

    return axios
      .post(`/v1/clients/questionnaire/${programId}`, {
        isDraft,
        dailyActivities: value,
        answers,
        step,
      })
      .then((r) => r.data.data)
  },
  async setPhysicalActivityAbilities({ commit, rootGetters }, payload) {
    let axios = rootGetters.axios
    let { programId, answers, value } = payload

    commit('setPhysicalActivityAbilities', value)
    for (let answer of answers) {
      commit('setAnswer', answer)
    }

    return axios
      .post(`/v1/clients/questionnaire/${programId}`, {
        isDraft: true,
        physicalActivityAbilities: value,
        answers,
      })
      .then((r) => r.data.data)
  },
  async setMedicalHistory({ rootGetters }, payload) {
    let axios = rootGetters.axios
    let { programId, answers, value } = payload

    // commit('setMedicalHistory', value)
    // for (let answer of answers) {
    //   commit('setAnswer', answer)
    // }

    return axios
      .post(`/v1/clients/questionnaire/${programId}`, {
        isDraft: true,
        answers,
        medicalHistory: value,
      })
      .then((r) => r.data.data)
  },
  async setFoodRecords({ commit, rootGetters }, payload) {
    let axios = rootGetters.axios
    let { programId, value, answers, step = 6 } = payload

    commit('setFoodRecords', value)
    for (let answer of answers) {
      commit('setAnswer', answer)
    }

    return axios
      .post(`/v1/clients/questionnaire/${programId}`, {
        isDraft: true,
        foodRecords: value,
        answers,
        step,
      })
      .then((r) => r.data.data)
  },
  async getByLatestProgram(ctx, clientId) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/nutritionist/clients/${clientId}/latest-program`)
      .then((r) => r.data.data.id)
      .then((id) => axios.get(`/v1/nutritionist/quizioner/${id}`))
      .then((r) => r.data.data)
  },
  get(ctx, programId) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/clients/quizioner/${programId}`)
      .then((r) => r.data.data)
      .then((r) => {
        ctx.commit('setGeneralData', r.generalData)
        ctx.commit('setAnthropometryData', r.anthropometryData)
        ctx.commit('setDietAndEatingHistory', r.dietAndEatingHistory)
        ctx.commit('setDailyActivities', r.dailyActivities)
        ctx.commit('setPhysicalActivityAbilities', r.physicalActivityAbilities)
        ctx.commit('setMedicalHistory', r.medicalHistory)
        // ctx.commit("setFoodRecords", r.foodRecords);
        return r
      })
  },
  post(ctx, programId) {
    let axios = ctx.rootGetters.axios

    let foodRecords = ctx.state.foodRecords
    if (
      !Array.isArray(foodRecords) &&
            Object.getPrototypeOf(foodRecords) === Object.prototype
    ) {
      foodRecords = Object.values(foodRecords)
      ctx.state.foodRecords = foodRecords
    }

    return axios
      .post(`/v1/clients/quizioner/${programId}`, ctx.state)
      .then((response) => {
        return response.data
      })
  },
  clear(ctx) {
    ctx.commit('setGeneralData', state.generalData)
    ctx.commit('setAnthropometryData', state.anthropometryData)
    ctx.commit('setDietAndEatingHistory', state.dietAndEatingHistory)
    ctx.commit('setDailyActivities', state.dailyActivities)
    ctx.commit('setPhysicalActivityAbilities', state.physicalActivityAbilities)
    ctx.commit('setMedicalHistory', state.medicalHistory)
    // ctx.commit('setFoodRecords', state.foodRecords)
  },
  getForNutri(ctx, programId) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/nutritionist/quizioner/${programId}`)
      .then((r) => r.data.data)
      .then((r) => {
        ctx.commit('setGeneralData', r.generalData)
        ctx.commit('setAnthropometryData', r.anthropometryData)
        ctx.commit('setDietAndEatingHistory', r.dietAndEatingHistory)
        ctx.commit('setDailyActivities', r.dailyActivities)
        ctx.commit('setPhysicalActivityAbilities', r.physicalActivityAbilities)
        ctx.commit('setMedicalHistory', r.medicalHistory)
        // ctx.commit('setFoodRecords', r.foodRecords)
        return r
      })
  },
  async list(ctx) {
    let axios = ctx.rootGetters.axios

    return axios.get('/v1/clients/quizioner').then((r) => r.data)
  },
  async getV2ClientForCategory({ commit, rootGetters }, category) {
    let axios = rootGetters.axios
    let resp = await axios
      .get(`/v1/clients/questionnaire-questions?category=${category}`)
      .then((r) => r.data.data)

    for (let item of resp) {
      commit('setQuestion', item)
    }

    // await dispatch("getV2ClientDraft")

    return resp
  },
  async getV2Client(ctx, programId) {
    let filters = [
      'informed_consent',
      'general_data',
      'anthropometry_data',
      'diet_and_eating_history',
      'daily_activities',
      'food_records',
      'physical_activity_abilities',
      'medical_history',
    ]

    let axios = ctx.rootGetters.axios
    let promises = []

    for (let category of filters) {
      let promise = axios
        .get(`/v1/clients/questionnaire-questions?category=${category}`)
        .then((r) => r.data.data)
      promises.push(promise)
    }

    promises = await Promise.all(promises)
    promises = promises.flatMap((it) => it)
    for (let item of promises) {
      ctx.commit('setQuestion', item)
    }

    await ctx.dispatch('getV2ClientAnswer', programId)

    return promises
  },
  async getV2NutriAnswers(ctx, programId) {
    const axios = ctx.rootGetters.axios
    let data = await axios
      .get(`/v1/nutritionist/quizioner/${programId}`)
      .then((r) => r.data.data)

    for (let answer of data.answers ?? []) {
      ctx.commit('setAnswer', answer)
    }

    ctx.commit('setInformedConsent', data.informedConsent)
    ctx.commit('setAnthropometryData', data.anthropometryData)
    ctx.commit('setDailyActivities', data.dailyActivities)
    ctx.commit('setDietAndEatingHistory', data.dietAndEatingHistory)
    // ctx.commit('setFoodRecords', data.foodRecords)
    ctx.commit('setGeneralData', data.generalData)
    ctx.commit('setMedicalHistory', data.medicalHistory)
    // ctx.commit('setPhysicalActivityAbilities', data.physicalActivityAbilities)

    return data
  },
  async _getV2ClientAnswers(ctx, { prefix = 'clients', programId, isDraft }) {
    let axios = ctx.rootGetters.axios
    let url = `/v1/${prefix}/questionnaire/${programId}`
    if (isDraft) {
      url += '?isDraft=true'
    }

    let data = await axios.get(url).then((r) => r.data.data)

    for (let answer of data.answers ?? []) {
      ctx.commit('setAnswer', answer)
    }

    ctx.commit('setInformedConsent', data.informedConsent)
    ctx.commit('setAnthropometryData', data.anthropometryData)
    ctx.commit('setDailyActivities', data.dailyActivities)
    ctx.commit('setDietAndEatingHistory', data.dietAndEatingHistory)
    ctx.commit('setFoodRecords', data.foodRecords)
    ctx.commit('setGeneralData', data.generalData)
    ctx.commit('setMedicalHistory', data.medicalHistory)
    // ctx.commit('setPhysicalActivityAbilities', data.physicalActivityAbilities)

    return data
  },
  async getV2ClientAnswer({ dispatch }, programId) {
    return dispatch('_getV2ClientAnswers', { programId, isDraft: false })
  },
  async getV2ClientDraft({ dispatch }, programId) {
    return dispatch('_getV2ClientAnswers', { programId, isDraft: true })
  },
  async getV2SuperadminAnswer({ dispatch }, { programId }) {
    return dispatch('_getV2ClientAnswers', {
      programId,
      prefix: 'super-admin',
      isDraft: false,
    })
  },
  async postV2({ state, dispatch, rootGetters }, programId) {
    let axios = rootGetters.axios

    // await dispatch('getV2Client', programId)
    await dispatch('getV2ClientDraft', programId)

    let informedConsent = state.informedConsent
    let questions = state.questions?.length > 0 ? state.questions?.map((it) => ({
      questionId: it?.id,
      answer: it?.answer,
      question: it?.question,
    })) : []
    let generalData = state.generalData
    let anthropometryData = state.anthropometryData
    let dailyActivities = state.dailyActivities
    let dietAndEatingHistory = state.dietAndEatingHistory
    let foodRecords = state.foodRecords
    let medicalHistory = state.medicalHistory

    let sportType = state.dailyActivities?.sportType?.map((it) => {
      if (it?.customType) {
        return {
          type: it?.customType,
          duration: it?.duration,
        }
      }
      return {
        type: it?.type,
        duration: it?.duration,
      }
    })
    dailyActivities.sportType = sportType
    
    let physicalLimitationActivity = state.dailyActivities?.physicalLimitationActivity?.map((it) => {
      if (it?.other) {
        return it?.other
      }
      return it?.value
    })
    dailyActivities.physicalLimitationActivity = physicalLimitationActivity

    let mentalDisorder = state.medicalHistory?.mentalDisorder?.map((it) => {
      if (it?.other) {
        return it?.other
      }
      return it?.value
    })
    let disease = state.medicalHistory?.disease?.map((it) => {
      if (it?.other) {
        return it?.other
      }
      return it?.value
    })
    medicalHistory.mentalDisorder = mentalDisorder
    medicalHistory.disease = disease
    // let physicalActivityAbilities = state.physicalActivityAbilities
    
    return axios
      .post(`/v1/clients/questionnaire/${programId}`, {
        isDraft: false,
        answers: questions,
        informedConsent,
        generalData,
        anthropometryData,
        dailyActivities,
        dietAndEatingHistory,
        foodRecords,
        medicalHistory,
        // physicalActivityAbilities,
      })
      .then((r) => r.data.data)
  },
  updateCurrentStep(ctx, step) {
    ctx.commit('setCurrentStep', step)
  },
  async reqStoreAnswerQuestionnaireDraft({ state, commit, rootGetters }, { programId, filter }) {
    // filter = 'informedConsent' | 'generalData' | 'anthropometryData' | 'foodRecords'
    let axios = rootGetters.axios
    const hasLocalDraft = state[filter] ? Object.values(state[filter]).some((it) => {
      if (Array.isArray(it)) {
        return it.length > 0
      }
      return ![undefined, null, NaN].includes(it)
    }) : false
    if (hasLocalDraft) {
      return state[filter]
    }
    const res = await axios
      .get(`/v1/clients/questionnaire-draft/${programId}`, {
        params: {
          filter,
        },
      })
      .then((r) => r.data.data)
    const commitType = {
      informedConsent: 'setInformedConsent',
      generalData: 'setGeneralData',
      anthropometryData: 'setAnthropometryData',
      foodRecords: 'setFoodRecords',
      dietAndEatingHistory: 'setDietAndEatingHistory',
      dailyActivities: 'setDailyActivities',
      medicalHistory: 'setMedicalHistory',
    }
    commit(commitType[filter], res[filter])
    return res
  },
  async getV2NutriQuestionnaire(ctx, programId) {
    const axios = ctx.rootGetters.axios
    let data = await axios
      .get(`/v1/nutritionist/questionnaire/${programId}`)
      .then((r) => r.data.data)
      
    return data
  },
  async getV2ClientQuestionnaire(ctx, programId) {
    const axios = ctx.rootGetters.axios
    let data = await axios
      .get(`/v1/clients/questionnaire/${programId}`)
      .then((r) => r.data.data)
      
    return data
  }, 
}
