<template>
  <div
    :class="context.classes.element"
    :data-type="context.type"
    style="position: relative;"
  >
    <FormulateSlot
      name="prefix"
      :context="context"
    >
      <component
        :is="context.slotComponents.prefix"
        v-if="context.slotComponents.prefix"
        :context="context"
      />
    </FormulateSlot>
    <c-box
      position="absolute"
      :left="['17px', '23px']"
      top="50%"
      transform="translateY(-50%)"
    >
      <img
        :style="[{height: '20px'}, attributes?.['icon-prefix-style'] ? attributes?.['icon-prefix-style']: {}]"
        :src="attributes?.['icon-prefix']"
        alt="icon prefix"
      >
    </c-box>
    <input
      v-model="context.model"
      :type="isShowPassword ? 'text' : 'password'"
      v-bind="attributes"
      :class="{
        default: true,
        err: context.validationErrors.length > 0 && context.showValidationErrors,
      }"
      @blur="context.blurHandler"
      v-on="$listeners"
    >
    <c-box
      position="absolute"
      :right="['17px', '23px']"
      top="50%"
      transform="translateY(-50%)"
      @click="toggleShowPassword"
    >
      <img
        v-if="isShowPassword"
        :style="[{height: '20px', width: '21px'}]"
        :src="hiddenPassword"
        alt="icon hidden password"
      >
      <img
        v-else
        :style="[{height: '20px', width: '21px'}]"
        :src="showPassword"
        alt="icon show password"
      >
    </c-box>
    <FormulateSlot
      name="suffix"
      :context="context"
    >
      <component
        :is="context.slotComponents.suffix"
        v-if="context.slotComponents.suffix"
        :context="context"
      />
    </FormulateSlot>
  </div>
</template>

<script>
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin'
import iconShowPassword from '@/assets/ic-show-password.svg'
import iconHiddenPassword from '@/assets/ic-hidden-password.svg'
export default {
  name: 'PasswordVueFormulate',
  mixins: [FormulateInputMixin],
  data() {
    return {
      isShowPassword: false,
      showPassword: iconShowPassword,
      hiddenPassword: iconHiddenPassword,
    }
  },
  methods: {
    toggleShowPassword() {
      this.isShowPassword = !this.isShowPassword
    },
  },
}
</script>

<style scoped>
.default {
  font-size: 14px;
  border-radius: 8px;
  width: 100%;
  border: 1px solid #888888;
  padding: 12px 14px 12px 45px;
  height: 45px;
}

.err {
  border: 1px solid red;
}

.default:focus-visible {
  border: 1px solid #008C81;
}

@media (min-width: 640px) {
  .default {
    padding: 12px 14px 12px 54px;
  }
}
</style>