export let namespaced = true

export let state = {}

export let mutations = {}

export let actions = {
  listNotifikasi(ctx, { page, perPage }) {
    let axios = ctx.rootGetters.axios
    let params = new URLSearchParams()
    if (page != null) params.set('page', page)
    if (perPage != null) params.set('perpage', perPage)

    return axios
      .get(`/v1/admin/notification-management?${params.toString()}`)
      .then((r) => r.data.data)
  },
  getNotifikasiById(ctx, notifikasiId) {
    let axios = ctx.rootGetters.axios

    return axios
      .get(`/v1/admin/notification-management/${notifikasiId}`)
      .then((r) => r.data.data)
  },
  create(ctx, notifikasi) {
    let axios = ctx.rootGetters.axios
    return axios
      .post('/v1/admin/notification-management', {
        name: notifikasi.name,
        type: notifikasi.type,
        content: notifikasi.content,
        targetRole: notifikasi.target,
        sendAt: notifikasi.dateTime,
      })
      .then((r) => r.data.data)
  },
  cancel(ctx, notifikasiId) {
    let axios = ctx.rootGetters.axios
    return axios.put(`/v1/admin/notification-management/${notifikasiId}/cancel`)
      .then(r => r.data.data)
  },
  edit(ctx, notifikasi) {
    let axios = ctx.rootGetters.axios
    return axios.put(`/v1/admin/notification-management/${notifikasi.id}`, {
      name: notifikasi.name,
      type: notifikasi.type,
      content: notifikasi.content,
      targetRole: notifikasi.targetRole,
      sendAt: notifikasi.dateTime,
    }).then(r => r.data.data)
  },
}
