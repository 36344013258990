<template>
  <label
    :for="context.id"
    style="font-size: 14px; margin-left: 10px;"
  >
    {{ context.label }}
  </label>
</template>

<script>
export default {
  name: 'CustomLabel',
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style>
/* styles? sure... */
</style>