<template>
  <c-box
    bg="white"
  >
    <portal-target
      name="header"
      slim
    />
    <portal-target name="main" />
    <router-view />

    <c-box
      pos="fixed"
      :bottom="['0px', '20px']"
      :right="['0px', '20px']"
      :width="['100%', 'auto']"
      z-index="999"
    >
      <c-alert
        v-if="alertUpdate"
        status="info"
        variant="left-accent"
      >
        <c-alert-icon />
        <c-box>
          <c-alert-title
            :mr="2"
            :mb="1"
          >
            Pembaruan Tersedia
          </c-alert-title>
          <c-alert-description>Refresh untuk mendapatkan versi terbaru.</c-alert-description>
          <c-flex
            gap="3"
            mt="4px"
          >
            <c-button
              size="sm"
              variant="solid"
              variant-color="blue"
              @click.stop="refreshApp"
            >
              Refresh
            </c-button>
            <c-button
              size="sm"
              variant="ghost"
              variant-color="blue"
              @click="alertUpdate = false"
            >
              Tutup
            </c-button>
          </c-flex>
        </c-box>
        <c-close-button 
          position="absolute" 
          right="8px" 
          top="8px"
          @click="alertUpdate = false"
        />
      </c-alert>
    </c-box>
  </c-box>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      refreshing: false,
      registration: null,
      alertUpdate: false,
    }
  },
  created() {
    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true })

    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        window.location.reload()
      })
    }
  },
  methods: {
    showRefreshUI(e) {
      // Display a snackbar inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      this.registration = e.detail
      this.alertUpdate = true
    },
    refreshApp() {
      this.alertUpdate = false

      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) { return }

      this.registration.waiting.postMessage('skipWaiting')
    },
  },
}
</script>

<style>
body {
  padding-bottom: 0 !important;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
/* Styling for c-modal */
[id*="modal-portal"] > div {
  /* add z-index to override header */
  z-index: 20; 
}
</style>