
export const namespaced = true
export const state = {
  questions: [],
  categories: [],
}

export const getters = {
  getQuestionsFor: (state) => (category) => {
    return state.questions.filter((question) => question.category === category)
  },
}

export const mutations = {
  setQuestions(state, questions) {
    state.questions = questions
  },
  mergeQuestions(state, questions) {
    for (let item of questions) {
      let index = state.questions.findIndex((question) => question.id === item.id)
      if (index === -1) {
        state.questions.push(item)
      } else {
        state.questions[index] = item
      }
    }
  },
  setCategories(state, categories) {
    state.categories = categories
  },
}

export const actions = {
  async getQuestions(ctx) {
    const axios = ctx.rootGetters.axios
    const resp = await axios.get('/v1/nutritionist/progress-tracker-questions').then(r => r.data)
    const questions = resp.data
    const categories = resp.meta.filter

    ctx.commit('setQuestions', questions)
    ctx.commit('setCategories', categories)
  },
  async getQuestionsForClientId(ctx, clientId) {
    const axios = ctx.rootGetters.axios
    const resp = await axios.get(`/v1/nutritionist/clients/${clientId}/progress-tracker-master`).then(r => r.data)
    const data = resp.data

    if (resp.message === 'Klien tidak memiliki program aktif') {
      throw new Error('Klien tidak memiliki program aktif')
    }
    const questions = data.questions
    const categories = resp.meta.filter

    ctx.commit('mergeQuestions', questions)
    ctx.commit('setCategories', categories)
    return data
  },
  async setQuestionsForClientId(ctx, { clientId, questions }) {
    const axios = ctx.rootGetters.axios
    const questionIds = questions.map(it => it.id)

    const resp = await axios.post(`/v1/nutritionist/clients/${clientId}/progress-tracker-master`, questionIds)
      .then(r => r.data)
    return resp
  },
  async getProgressForProgramId(ctx, programId) {
    const axios = ctx.rootGetters.axios
    const resp = await axios.get(`/v1/clients/programs/${programId}/progress-tracker`).then(r => r.data)
    return resp
  },
  async getProgressForClientId(ctx, clientId) {
    const axios = ctx.rootGetters.axios
    const resp = await axios.get(`/v1/nutritionist/clients/${clientId}/progress-tracker`).then(r => r.data.data)
    return resp
  },
  async getProgressForId(ctx, progressId) {
    const axios = ctx.rootGetters.axios
    const resp = await axios.get(`/v1/nutritionist/progress-tracker/${progressId}`).then(r => r.data.data)
    return resp
  },
  async submitFeedback(ctx, payload) {
    const axios = ctx.rootGetters.axios
    const { id, ...rest } = payload
    const resp = await axios.post(`/v1/nutritionist/progress-tracker/${id}/feedback`, rest).then(r => r.data)
    return resp
  },
  async getFeedback(ctx, id) {
    const axios = ctx.rootGetters.axios
    const resp = await axios.get(`/v1/nutritionist/progress-tracker/${id}/feedback`).then(r => r.data.data)
    return resp
  },
}
