export const namespaced = true

export const state = {
  item: {},
}

export const getters = {
  item: (state) => state.item,
}

export const mutations = {
  setItem(state, item) {
    state.item = item
  },
}

export const actions = {
  async listCouponsAdmin(ctx, params) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/admin/coupons', { params: params })
      .then((it) => it.data)
      .then((it) => {
        return it
      })
      .catch(() => {
        return { data: [], meta: 1 }
      })
  },
  createCouponsAdmin(ctx, data) {
    let axios = ctx.rootGetters.axios
    return axios
      .post('/v1/admin/coupons', data)
      .then((it) => it.data.data)
      .then((it) => {
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  detailCouponsAdmin(ctx, id) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/admin/coupons/${id}`)
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setItem', it.data)
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  deleteCouponsAdmin(ctx, id) {
    let axios = ctx.rootGetters.axios
    return axios
      .delete(`/v1/admin/coupons/${id}`)
      .then((it) => it.data.data)
      .catch((err) => {
        throw err.response
      })
  },
  updateCouponsAdmin(ctx, data) {
    let axios = ctx.rootGetters.axios
    return axios
      .put(`/v1/admin/coupons/${data.id}`, data)
      .then((it) => it.data.data)
      .catch((err) => {
        throw err.response
      })
  },
}
