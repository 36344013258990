export const namespaced = true

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async getChartData(ctx) {
    const axios = ctx.rootGetters.axios

    const resp = await axios
      .get('/v1/clients/progress-tracker-master')
      .then((r) => r.data.data)

    return resp
  },
  async getQuestions(ctx) {
    const axios = ctx.rootGetters.axios

    const resp = await axios
      .get('/v1/clients/progress-tracker-master')
      .then((r) => r.data.data)

    return resp
  },
  async getProgressData(ctx, params) {
    const axios = ctx.rootGetters.axios
    let id = params?.id
    let resp

    if (id == null) {
      resp = await axios
        .get('/v1/clients/progress-tracker')
        .then((r) => r.data.data)
    } else {
      resp = await axios.get(`/v1/clients/programs/${id}/progress-tracker`)
        .then(r => r.data.data)
    }

    return resp
  },
  async submission(ctx, payload) {
    const axios = ctx.rootGetters.axios
    const resp = await axios
      .post('/v1/clients/progress-tracker', payload)
      .then((r) => r.data)

    return resp
  },
  async getEmptyProgress(ctx) {
    const axios = ctx.rootGetters.axios
    const resp = await axios
      .get('/v1/clients/progress-tracker-reminder')
      .then((r) => r.data.data)

    return resp
  },
  async getProgressById(ctx, progressId) {
    const axios = ctx.rootGetters.axios
    const resp = await axios.get(`/v1/clients/progress-tracker/${progressId}`)
      .then((r) => r.data.data)
    return resp
  },
  async getProgressFeedbackById(ctx, progressId) {
    const axios = ctx.rootGetters.axios
    const resp = await axios.get(`/v1/clients/progress-tracker/${progressId}/feedback`)
      .then((r) => r.data.data)
    return resp
  },
  async getHistory(ctx) {
    const axios = ctx.rootGetters.axios
    return axios.get('/v1/clients/progress-tracker/history')
      .then(r => r.data.data)
  },
}
