export const namespaced = true
export const state = {
  items: [],
  item: {},
  meta: {},
  optionCategory: [
    { value: 'informed_consent', label: 'Informed Consent' },
    { value: 'general_data', label: 'General Data' },
    { value: 'anthropometry_data', label: 'Anthropometry Data' },
    { value: 'diet_and_eating_history', label: 'Diet and Eating History' },
    { value: 'daily_activities', label: 'Daily Activities' },
    { value: 'food_records', label: 'Food Records' },
    { value: 'physical_activity_abilities', label: 'Physical Activity Abilities' },
    { value: 'medical_history', label: 'Medical History' },
  ],
  optionAnswerType: [
    { value: 'short_answer', label: 'Sort Answer' },
    { value: 'paragraph', label: 'Paragraph' },
    { value: 'number', label: 'Number' },
    { value: 'dropdown', label: 'Dropdown' },
    { value: 'multiple_choice', label: 'Multiple Choice' },
    { value: 'checkboxes', label: 'Checkboxes' },
    { value: 'date_picker', label: 'Date Picker' },
  ],
}
export const mutations = {
  setItems(state, items) {
    state.items = items
  },
  setItem(state, item) {
    state.item = item
  },
  setMeta(state, meta) {
    state.meta = meta
  },
}
export const actions = {
  async listKuesionerSuperadmin(ctx, params) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/super-admin/question-management', { params: params })
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setItems', it.data)
        ctx.commit('setMeta', it.meta)
      })
      .catch(() => {
        ctx.commit('setItems', [])
        ctx.commit('setMeta', 1)
      })
  },
  createKuesionerSuperadmin(ctx, data) {
    let axios = ctx.rootGetters.axios
    return axios
      .post('/v1/super-admin/question-management', data)
      .then((it) => it.data.data)
      .then((it) => {
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  detailKuesionerSuperadmin(ctx, id) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/super-admin/question-management/${id}`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setItem', it)
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },

  deleteKuesionerSuperadmin(ctx, id) {
    let axios = ctx.rootGetters.axios
    return axios
      .delete(`/v1/super-admin/question-management/${id}`)
      .then((it) => it.data.data)
      .catch((err) => {
        throw err.response
      })
  },
  updateKuesionerSuperadmin(ctx, data) {
    let axios = ctx.rootGetters.axios
    return axios
      .put(`/v1/super-admin/question-management/${data.id}`, data)
      .then((it) => it.data.data)
      .catch((err) => {
        throw err.response
      })
  },
}
