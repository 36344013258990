import posthog from 'posthog-js'

export const namespaced = true

export const state = () => ({
  user: null,
  token: null,
  refreshToken: null,
  isGoogle: 0,
  redirect: null,
})

export const getters = {
  isAuthenticated: (state) => !!state.token,
  fullName: (s) => `${s.user?.firstName} ${s.user?.lastName}`,
  redirect: state => state.redirect,
  userId: state => state.user?.id,
  isKol: state => state.user?.isKol ? true : false,
}

export const mutations = {
  setUser(state, user) {
    state.user = user
  },
  setToken(state, token) {
    state.token = token
  },
  setRefreshToken(state, token) {
    state.refreshToken = token
  },
  setIsGoogle(state, google) {
    state.isGoogle = google
  },
  setRedirect(state, redirect) {
    state.redirect = redirect
  },
}

export const actions = {
  /**
   * @param {Object} data - data to be sent
   * @param {string} data.lastname
   * @param {string} data.firstname
   * @param {string} data.password
   * @param {string} data.email
   * @param {string} data.phone
   * @param {string | undefined} [data.role] - optional when `otp` is present
   * @param {number | undefined} [data.zipCode] - optional when `otp` is present
   * @param {string | undefined} [data.city] - optional when `otp` is present
   * @param {string | undefined} [data.address] - optional when `otp` is present
   * @param {string | undefined} [data.birthPlace] - optional when `otp` is present
   * @param {string | undefined} [data.birthdate] - optional when `otp` is present  |  in `yyyy-mm-dd` format
   * @param {string | undefined} [data.country] - optional when `otp` is present
   * @param {string | undefined} [data.otp]
   *
   * @returns {Promise<{
   *     status: true,
   *     message: "OK",
   *     data: {
   *         id: string,
   *         email: string,
   *         firstName: string,
   *         lastName: string,
   *         status: ("verified"),
   *         photoUrl: (string | null),
   *         role: string,
   *         clientType: string,
   *         isGoogle: number,
   *         token: string,
   *         refreshToken: string
   *     },
   *     "meta": {}
   * }>}
   */
  async signup(ctx, data) {
    let axios = ctx.rootGetters.axios

    // await wait(4000)
    return axios
      .post('/v1/users/register', {
        firstName: data.firstname,
        lastName: data.lastname,
        birthDate: data.birthdate,
        country: data.country,
        phone: data.phone,
        email: data.email,
        password: data.password,
        otp: data.otp,
      })
      .then((it) => it.data)
      .catch((e) => {
        throw new Error(e.response.data.message || 'Something went wrong!')
      })
  },
  async signin(ctx, { email, password }) {
    let axios = ctx.rootGetters.axios
    return axios
      .post('/v1/auth/login', { email, password })
      .then((it) => {
        let { token, refreshToken, ...user } = it.data.data
        ctx.commit('setUser', user)
        ctx.commit('setToken', token)
        ctx.commit('setRefreshToken', refreshToken)
        ctx.commit('setIsGoogle', user.isGoogle)

        ctx.dispatch('userType/triggerUpdateUserType', '', { root: true })

        const user_ = it?.data?.data || {}
        if (user_.id) {
          posthog.identify(user_.id, user_)
        }
        return user
      })
      .catch((e) => {
        throw new Error(e.response.data.message || 'Something went wrong!')
      })
  },
  async signin2(ctx, { email, password }) {
    try {
      let axios = ctx.rootGetters.axios
      const res = await axios.post('/v1/auth/login', { email, password })
      if (res.data?.status === false) {
        throw new Error(res.data?.message || 'Something went wrong!')
      }
      // response:
      // 1. user menginputkan kode otp yang benar
      // {
      //     data: "https://accounts.google.com/o/oauth2/v2/auth?access_type=online&client_id=503858743674-8u990uc829lutpd4vbl8v5jn4jlpqdtc.apps.googleusercontent.com&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuser.birthday.read&response_type=code&redirect_uri=https%3A%2F%2Fapp.staging.dietela.io",
      //   }
      //
      ctx.dispatch('userType/triggerUpdateUserType', '', { root: true })

      const user = res?.data?.data || {}
      if (user.id) {
        posthog.identify(user.id, user)
      }
      return res.data
    } catch (e) {
      throw new Error(e.response.data.message)
    }
  },
  async signout(ctx) {
    let axios = ctx.rootGetters.axios
    return axios.post('/v1/auth/logout').then(() => {
      ctx.commit('setUser', null)
      ctx.commit('setToken', null)
      ctx.dispatch('userType/resetUserType', '', { root: true })
      posthog.reset()
    })
  },
  async updateIsGoogle(ctx) {
    ctx.commit('setIsGoogle', 0)
  },
  async refreshToken(ctx) {
    let axios = ctx.rootGetters.axios
    let { refreshToken } = ctx.state

    return axios
      .post('/v1/auth/refresh-token', {
        userId: ctx.state.user.id,
        refreshToken,
      })
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setToken', it.token)
        return it.token
      })
  },
  forgotPassword(ctx, email) {
    let axios = ctx.rootGetters.axios
    return axios
      .put('/v1/auth/reset-password-email', email)
      .then((it) => it.data.data)
      .then((it) => {
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  async resetPassword(ctx, body) {
    try {
      let axios = ctx.rootGetters.axios
      const res = await axios.put('/v1/auth/reset-password', {
        token: body.token,
        password: body.password,
      })
      return res.data.data
    } catch (e) {
      throw e.response
    }
  },
  async setPassword(ctx, body) {
    try {
      let axios = ctx.rootGetters.axios
      const res = await axios.put('/v1/auth/reset-password', {
        token: body.token,
        password: body.password,
      })
      return res.data.data
    } catch (e) {
      throw e.response
    }
  },
  async updateProfile(ctx, body) {
    try {
      let axios = ctx.rootGetters.axios
      const res = await axios.put('/v1/users/update-profile', {
        firstName: body.firstName,
        lastName: body.lastName,
        birthDate: body.birthDate,
        country: body.country,
        phone: body.phone,
        email: body.email,
        token: body.token,
      })
      return res.data.data
    } catch (e) {
      throw e.response
    }
  },
  async resendVerification(ctx, email) {
    try {
      let axios = ctx.rootGetters.axios
      const res = await axios.put('/v1/users/verify-email', email)
      if (res.data.status === false) {
        throw new Error(res.data.message || 'Something went wrong!')
      }
      return res.data
    } catch (e) {
      throw new Error(e.response.data.message)
    }
  },
  /**
   * @param {Object} data
   * @param {string} data.email
   *
   * @returns { Promise<{unknown}> }
   */
  async emailOtpSend(ctx, { email }) {
    try {
      let axios = ctx.rootGetters.axios
      const res = await axios.post('/v1/auth/email-otp-send', { email })
      if (res.data?.status === false) {
        throw new Error(res.data?.message || 'Something went wrong!')
      }
      // response:
      // 1. user menginputkan email yang sudah terdaftar
      // {
      //     isRegistered: true,
      //   }
      //
      // 2. user menginputkan email yang belum terdaftar
      // {
      //     isRegistered: false,
      //   }
      return res.data
    } catch (e) {
      throw new Error(e?.response?.data?.message)
    }
  },
  /**
   * @param {Object} data
   * @param {string} data.email
   * @param {string} data.otp
   *
   * @returns { Promise<{unknown}> }
   */
  async emailOtpVerify(ctx, { email, otp }) {
    try {
      let axios = ctx.rootGetters.axios
      const res = await axios.post('/v1/auth/email-otp-verify', { email, otp })
      if (res.data?.status === false) {
        throw new Error(res.data?.message)
      }
      // response:
      // 1. user menginputkan kode otp yang benar
      // {
      //     status: true,
      //     message: 'SUCCES_VERIVY',
      //   }
      //
      // 2. user menginputkan kode otp yang salah
      // {
      //     status: false,
      //     message: 'WRONG_OTP',
      //   }
      return res.data
    } catch (e) {
      throw new Error(e?.message || e?.response?.data?.message)
    }
  },
  /**
   * @returns { Promise<{unknown}> }
   */
  async generateGoogleUrl(ctx) {
    try {
      let axios = ctx.rootGetters.axios
      const res = await axios.post('/v1/auth/generate-google-url')
      if (res.data?.status === false) {
        throw new Error(res.data?.message || 'Something went wrong!')
      }
      // response:
      // 1. user menginputkan kode otp yang benar
      // {
      //     data: "https://accounts.google.com/o/oauth2/v2/auth?access_type=online&client_id=503858743674-8u990uc829lutpd4vbl8v5jn4jlpqdtc.apps.googleusercontent.com&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuser.birthday.read&response_type=code&redirect_uri=https%3A%2F%2Fapp.staging.dietela.io",
      //   }
      //
      return res.data
    } catch (e) {
      throw new Error(e.response.data.message)
    }
  },
}
