export const namespaced = true

export const state = {
  userType: '', // 'registered' / 'client' / 'ex-client' / ''
}

export const getters = {
  userType: (state) => {
    return state?.userType || '-'
  },
}
export const mutations = {
  setUserType(state, userType) {
    state.userType = userType
  },
}

export const actions = {
  triggerUpdateUserType(ctx) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/clients/client-status')
      .then((it) => {
        const clientStatus = it.data?.data?.clientStatus
        if (['registered', 'client', 'ex-client'].includes(clientStatus)) {
          ctx.commit('setUserType', clientStatus)
          return
        }
        ctx.commit('setUserType', '')
      })
      .catch((e) => {
        throw new Error(e.response.data.message || 'Something went wrong!')
      })
  },
  resetUserType(ctx) {
    ctx.commit('setUserType', '')
  },
}
