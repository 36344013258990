export const namespaced = true

export const state = {
  isLoading: {
    delete: false,
    deleteAll: false,
    fetchList: false,
  },
  items: [],
  discount: { type: 'promo', deduction: 9900 },
  itemCheckout: {},
  clientKey: 'SB-Mid-client-3qxIWTWo9eAmtJVQ',
  transactionToken: 'c6bcfb8c-91c7-4463-954f-5cbe4be2336c',
  selectedCart: '',
  // selectedCart: { // or this
  //   quantity: {},
  //   productService: {},
  //   _isBuyNow: true,
  // },
}

export const getters = {
  selectedCart: (state) => {
    if (state.selectedCart?._isBuyNow) {
      return state.selectedCart
    }
    const cartId = state?.selectedCart?.id || state?.selectedCart
    return state.items.find((item) => item.id === cartId) || {}
  },
  isLoading: (state) => state.isLoading,
  hasCart: (state) => state.items?.filter((v) => v.quantity >= 1)?.length >= 1,
  items: (state) => state.items?.filter((v) => v.quantity >= 1) || [],
  itemCheckout: (state) => state.itemCheckout,
  transactionToken: (state) => state.transactionToken,
  listCartsEnable: (state) => state.items?.filter((v) => v.isCanPurchase),
  listCartsDisable: (state) => state.items?.filter((v) => !v.isCanPurchase),
  getItemsQuantity: (state) => {
    return state.items.reduce((acc, it) => acc + it.quantity, 0)
  },
}

export const mutations = {
  setCartItems(state, items) {
    state.items = items
  },
  deleteCartItems(state, item) {
    state.items = state.items.filter((dt) => dt.id !== item)
  },
  setItemCheckout(state, item) {
    state.itemCheckout = item
  },
  setTransactionToken(state, token) {
    state.transactionToken = token
  },
  setIsloading(state, items) {
    state.isLoading = { ...state.isLoading, ...items }
  },
  setSelectedCart(state, item) {
    state.selectedCart = item
  },
}
export const actions = {
  async list(ctx) {
    ctx.commit('setIsloading', { fetchList: true })
    let axios = await ctx.rootGetters.axios
    return axios
      .get('/v1/clients/carts')
      .then((it) => {
        ctx.commit('setCartItems', it.data.data)
        return it.data.data
      })
      .finally(() => {
        ctx.commit('setIsloading', { fetchList: false })
      })
  },
  onUpdateCart(ctx, item) {
    let axios = ctx.rootGetters.axios
    return axios
      .post('/v1/clients/carts/', {
        productServiceId: item.productServiceId || null, // string!
        quantity: item.quantity || null, //int
        extend: item.extend || null, //int
      })
      .then((it) => {
        ctx.commit('setTransactionToken', it.data.data.token)
        return it.data.data
      })
  },
  onSubmitCheckout(ctx, item) {
    ctx.commit('setItemCheckout', item)
  },
  onSubmitPayment(ctx, cart) {
    let axios = ctx.rootGetters.axios
    return axios
      .post(`/v1/clients/carts/${cart.id}/checkout`, cart.product)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setTransactionToken', it.token)
        return it
      })
  },
  onDeleteProduct(ctx, productId) {
    let axios = ctx.rootGetters.axios
    ctx.commit('setIsloading', { delete: true })
    return axios
      .delete(`/v1/clients/carts/${productId}`)
      .then((it) => it.data.data)
      .finally(() => {
        ctx.dispatch('list')
        ctx.commit('setIsloading', { delete: false })
      })
  },
  async onDeleteAll(ctx) {
    let axios = ctx.rootGetters.axios
    ctx.commit('setIsloading', { deleteAll: true })
    const arrPromises = []
    const carts = await axios.get('/v1/clients/carts').then((it) => it.data.data)
    carts.forEach((cart) => {
      if (cart.isCanPurchase === false) {
        arrPromises.push(axios.delete(`/v1/clients/carts/${cart.id}`))
      }
    })
    return await Promise.all(arrPromises).finally(() => {
      ctx.dispatch('list')
      ctx.commit('setIsloading', { deleteAll: false })
    })
  },
}
