export const namespaced = true

export const state = {
  items: [],
  item: {},
  meta: {},
  types: [],
  category: [],
  categoryCoaching: [
    { value: '', label: 'Semua' },
    { value: 'diet_anak', label: 'Diet anak' },
    { value: 'diet_remaja', label: 'Diet remaja' },
    { value: 'diet_dewasa', label: 'Diet dewasa' },
    { value: 'diet_lansia', label: 'Diet lansia' },
    { value: 'diet_plant-based', label: 'Diet plant-based' },
    { value: 'manajemen_berat_badan_komposisi_tubuh', label: 'Manajemen berat badan & komposisi tubuh' },
    { value: 'gangguan_makan', label: 'Gangguan makan (disordered eating)' },
    { value: 'gizi_olahraga', label: 'Gizi olahraga' },
    { value: 'PCOS', label: 'PCOS' },
    { value: 'persiapan_kehamilan', label: 'Persiapan kehamilan' },
    { value: 'MP-ASI', label: 'MP-ASI' },
    { value: 'asam_urat', label: 'Asam urat' },
    { value: 'diabetes_mellitus', label: 'Diabetes mellitus' },
    { value: 'kolesterol', label: 'Kolesterol' },
    { value: 'hipertensi', label: 'Hipertensi' },
    { value: 'kanker', label: 'Kanker' },
    { value: 'penyakit_jantung', label: 'Penyakit jantung' },
    { value: 'penyakit_hati', label: 'Penyakit hati' },
    { value: 'penyakit_ginjal', label: 'Penyakit ginjal' },
    { value: 'lainnya', label: 'Lainnya' },
  ],
  labelCategory: '',
}

export const getters = {
  items: (state) => state.items,
  item: (state) => state.item,
  meta: (state) => state.meta,
  types: (state) => state.types,
  category: (state) => state.category,
  categoryCoaching: (state) => state.categoryCoaching,
  labelCategory: (state) => state.labelCategory,
}

export const mutations = {
  setItems(state, items) {
    state.items = items
  },
  setItem(state, item) {
    state.item = item
  },
  setMeta(state, meta) {
    state.meta = meta
  },
  setTypes(state, types) {
    state.types = types
  },
  setCategory(state, category) {
    state.category = category
  },
  setLabelCategory(state, data) {
    if (data.type == 'coaching_course') {
      state.labelCategory = state.categoryCoaching.find((dt) => dt.value === data.category)?.label
    } else {
      state.labelCategory = state.category.find((dt) => dt.value === data.category)?.label
    }
  },
}

export const actions = {
  // client
  getContentType(ctx) {
    const role = ctx.rootState.auth.user?.role === 'nutritionist' ? 'nutritionist' : 'clients'
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/${role}/content-types`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setTypes', it)
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  async listContentChallengeClient(ctx, params) {
    const role = ctx.rootState.auth.user?.role === 'nutritionist' ? 'nutritionist' : 'clients'
    // readStatus = 0 // belum dibaca
    // readStatus = 1 // sudah dibaca
    // readStatus = 2 // bisa dibaca
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/${role}/content-and-challenge`, { params: params })
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setItems', it.data)
        ctx.commit('setMeta', it.meta)
      })
  },
  getContentCategory(ctx, params) {
    const role = ctx.rootState.auth.user?.role === 'nutritionist' ? 'nutritionist' : 'clients'
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/${role}/content-categories`, {
        params: params,
      })
      .then((it) => {
        it.data.data
        ctx.commit('setCategory', it.data.data)
        return it.data
      })
      .catch((err) => {
        throw err.response
      })
  },
  detailContentChallengeClient(ctx, id) {
    const role = ctx.rootState.auth.user?.role === 'nutritionist' ? 'nutritionist' : 'clients'
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/${role}/content-and-challenge/${id}`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setItem', it)
      })
      .catch((err) => {
        throw err.response
      })
  },
  getLabelCategory(ctx, item) {
    ctx.commit('setLabelCategory', { type: item.type, category: item.category })
  },
  getContentFavorite(ctx, params = null) {
    const role = ctx.rootState.auth.user?.role === 'nutritionist' ? 'nutritionist' : 'clients'
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/${role}/content-favorite`, { params: params })
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setItems', it.data)
        ctx.commit('setMeta', it.meta)
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  addContentFavorite(ctx, data) {
    const role = ctx.rootState.auth.user?.role === 'nutritionist' ? 'nutritionist' : 'clients'
    let axios = ctx.rootGetters.axios
    return axios
      .post(`/v1/${role}/content-favorite`, data)
      .then((it) => it.data.data)
      .then((it) => {
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  deleteContentFavorite(ctx, payload) {
    const role = ctx.rootState.auth.user?.role === 'nutritionist' ? 'nutritionist' : 'clients'
    let axios = ctx.rootGetters.axios
    return axios
      .delete(`/v1/${role}/content-favorite`, { data: payload })
      .then((it) => it.data.data)
      .then((it) => {
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  readContent(ctx, id) {
    let axios = ctx.rootGetters.axios
    return axios
      .put(`/v1/clients/content-and-challenge/${id}/read`)
      .then((it) => it.data)
      .then((it) => {
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  
  // nutritionist
  async listContentChallengeNutritionist(ctx, params) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/nutritionist/content-and-challenge', { params: params })
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setItems', it.data)
        ctx.commit('setMeta', it.meta)
      })
  },
  detailContentChallengeNutritionist(ctx, id) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/nutritionist/content-and-challenge/${id}`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setItem', it)
      })
      .catch((err) => {
        throw err.response
      })
  },

  // superadmin
  async listContentChallengeSuperadmin(ctx, params) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/super-admin/content-and-challenge', { params: params })
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setItems', it.data.rows)
        ctx.commit('setMeta', it.meta)
      })
      .catch(() => {
        ctx.commit('setItems', [])
        ctx.commit('setMeta', 1)
      })
  },
  createContentChallengeSuperadmin(ctx, data) {
    let axios = ctx.rootGetters.axios
    return axios
      .post('/v1/super-admin/content-and-challenge', data)
      .then((it) => it.data.data)
      .then((it) => {
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  detailContentChallengeSuperadmin(ctx, id) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/super-admin/content-and-challenge/${id}`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setItem', it)
      })
      .catch((err) => {
        throw err.response
      })
  },
  deleteContentChallengeSuperadmin(ctx, id) {
    let axios = ctx.rootGetters.axios
    return axios
      .delete(`/v1/super-admin/content-and-challenge/${id}`)
      .then((it) => it.data.data)
      .catch((err) => {
        throw err.response
      })
  },
  updateContentChallengeSuperadmin(ctx, data) {
    let axios = ctx.rootGetters.axios
    return axios
      .put(`/v1/super-admin/content-and-challenge/${data.id}`, data)
      .then((it) => it.data.data)
      .catch((err) => {
        throw err.response
      })
  },

  // superadmin
  async listContentChallengeAdmin(ctx, params) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/admin/content-and-challenge', { params: params })
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setItems', it.data.rows)
        ctx.commit('setMeta', it.meta)
      })
      .catch(() => {
        ctx.commit('setItems', [])
        ctx.commit('setMeta', 1)
      })
  },
  createContentChallengeAdmin(ctx, data) {
    let axios = ctx.rootGetters.axios
    return axios
      .post('/v1/admin/content-and-challenge', data)
      .then((it) => it.data.data)
      .then((it) => {
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  detailContentChallengeAdmin(ctx, id) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/admin/content-and-challenge/${id}`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setItem', it)
      })
      .catch((err) => {
        throw err.response
      })
  },
  deleteContentChallengeAdmin(ctx, id) {
    let axios = ctx.rootGetters.axios
    return axios
      .delete(`/v1/admin/content-and-challenge/${id}`)
      .then((it) => it.data.data)
      .catch((err) => {
        throw err.response
      })
  },
  updateContentChallengeAdmin(ctx, data) {
    let axios = ctx.rootGetters.axios
    return axios
      .put(`/v1/admin/content-and-challenge/${data.id}`, data)
      .then((it) => it.data.data)
      .catch((err) => {
        throw err.response
      })
  },
}
