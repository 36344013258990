var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "bg": "white"
    }
  }, [_c('portal-target', {
    attrs: {
      "name": "header",
      "slim": ""
    }
  }), _c('portal-target', {
    attrs: {
      "name": "main"
    }
  }), _c('router-view'), _c('c-box', {
    attrs: {
      "pos": "fixed",
      "bottom": ['0px', '20px'],
      "right": ['0px', '20px'],
      "width": ['100%', 'auto'],
      "z-index": "999"
    }
  }, [_vm.alertUpdate ? _c('c-alert', {
    attrs: {
      "status": "info",
      "variant": "left-accent"
    }
  }, [_c('c-alert-icon'), _c('c-box', [_c('c-alert-title', {
    attrs: {
      "mr": 2,
      "mb": 1
    }
  }, [_vm._v(" Pembaruan Tersedia ")]), _c('c-alert-description', [_vm._v("Refresh untuk mendapatkan versi terbaru.")]), _c('c-flex', {
    attrs: {
      "gap": "3",
      "mt": "4px"
    }
  }, [_c('c-button', {
    attrs: {
      "size": "sm",
      "variant": "solid",
      "variant-color": "blue"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.refreshApp.apply(null, arguments);
      }
    }
  }, [_vm._v(" Refresh ")]), _c('c-button', {
    attrs: {
      "size": "sm",
      "variant": "ghost",
      "variant-color": "blue"
    },
    on: {
      "click": function click($event) {
        _vm.alertUpdate = false;
      }
    }
  }, [_vm._v(" Tutup ")])], 1)], 1), _c('c-close-button', {
    attrs: {
      "position": "absolute",
      "right": "8px",
      "top": "8px"
    },
    on: {
      "click": function click($event) {
        _vm.alertUpdate = false;
      }
    }
  })], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }