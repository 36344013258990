<template>
  <div
    :class="context.classes.element"
    :data-type="context.type"
    :data-multiple="attributes && attributes.multiple !== undefined"
    style="position: relative;"
  >
    <FormulateSlot
      name="prefix"
      :context="context"
    >
      <component
        :is="context.slotComponents.prefix"
        v-if="context.slotComponents.prefix"
        :context="context"
      />
    </FormulateSlot>
    
    <c-box
      position="absolute"
      :left="['17px', '23px']"
      top="50%"
      transform="translateY(-50%)"
    >
      <img
        :style="[{height: '20px', color: 'red'}, attributes?.['icon-prefix-style'] ? attributes?.['icon-prefix-style']: {}]"
        :src="attributes?.['icon-prefix']"
        alt="icon prefix"
      >
    </c-box>
    <label
      for="custom-select"
      :class="{
        default: true,
        err: context.validationErrors.length > 0 && context.showValidationErrors,
      }"
      style="position: absolute; height: 100%; display: flex; align-items: center"
    >
      {{ value || context.model || context.placeholder }}
    </label>
    <select
      id="custom-select"
      v-model="context.model"
      v-bind="attributes"
      :data-placeholder-selected="placeholderSelected"
      :class="{
        default: true,
        err: context.validationErrors.length > 0 && context.showValidationErrors,
      }"
      style="opacity: 0; cursor: pointer;"
      @change="onChange($event)"
      v-on="$listeners"
      @blur="context.blurHandler"
    >
      <option
        v-if="context.placeholder"
        value
        hidden="hidden"
        disabled
        :selected="!hasValue"
      >
        {{ context.placeholder }}
      </option>
      <template
        v-if="!optionGroups"
      >
        <option
          v-for="option in options"
          :key="option.id"
          :value="option.value"
          :disabled="!!option.disabled"
          v-bind="option.attributes || option.attrs || {}"
          v-text="option.label"
        />
      </template>
      <template
        v-else
      >
        <optgroup
          v-for="(subOptions, label) in optionGroups"
          :key="label"
          :label="label"
        >
          <option
            v-for="option in subOptions"
            :key="option.id"
            :value="option.value"
            :disabled="!!option.disabled"
            v-bind="option.attributes || option.attrs || {}"
            v-text="option.label"
          />
        </optgroup>
      </template>
    </select>

    <FormulateSlot
      name="suffix"
      :context="context"
    >
      <component
        :is="context.slotComponents.suffix"
        v-if="context.slotComponents.suffix"
        :context="context"
      />
    </FormulateSlot>
  </div>
</template>

<script>
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin'

export default {
  name: 'SelectVueFormulate',
  mixins: [FormulateInputMixin],
  data() {
    return {
      value: false,
    }
  },
  computed: {
    options() {
      return this.context.options || {}
    },
    optionGroups() {
      return this.context.optionGroups || false
    },
    placeholderSelected() {
      return !!(!this.hasValue && this.context.attributes && this.context.attributes.placeholder)
    },
  },
  methods: {
    onChange(event) {
      this.value = event.target.value
    },
  },
}
</script>

<style scoped>
.default {
  font-size: 14px;
  border-radius: 8px;
  width: 100%;
  border: 1px solid #888888;
  background-color: transparent;
  padding: 14px 14px 14px 45px;
  height: 45px;
}

.err {
  border: 1px solid red;
}

.default:focus-visible {
  border: 1px solid #008C81;
}

label:focus-visible {
  border: 1px solid #008C81;
}

select::after {
  content: "";
  width: 0.8em;
  height: 0.5em;
  background-color: black;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
}

@media (min-width: 640px) {
  .default {
    padding: 12px 14px 12px 54px;
  }
} 
</style>