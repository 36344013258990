export const namespaced = true

export const state = {
  items: [],
  item: {},
  meta: {},
  types: [],
  category: [],
  contentSections: [],
  categoryCoaching: [
    { value: '', label: 'Semua' },
    { value: 'diet_anak', label: 'Diet anak' },
    { value: 'diet_remaja', label: 'Diet remaja' },
    { value: 'diet_dewasa', label: 'Diet dewasa' },
    { value: 'diet_lansia', label: 'Diet lansia' },
    { value: 'diet_plant-based', label: 'Diet plant-based' },
    { value: 'manajemen_berat_badan_komposisi_tubuh', label: 'Manajemen berat badan & komposisi tubuh' },
    { value: 'gangguan_makan', label: 'Gangguan makan (disordered eating)' },
    { value: 'gizi_olahraga', label: 'Gizi olahraga' },
    { value: 'PCOS', label: 'PCOS' },
    { value: 'persiapan_kehamilan', label: 'Persiapan kehamilan' },
    { value: 'MP-ASI', label: 'MP-ASI' },
    { value: 'asam_urat', label: 'Asam urat' },
    { value: 'diabetes_mellitus', label: 'Diabetes mellitus' },
    { value: 'kolesterol', label: 'Kolesterol' },
    { value: 'hipertensi', label: 'Hipertensi' },
    { value: 'kanker', label: 'Kanker' },
    { value: 'penyakit_jantung', label: 'Penyakit jantung' },
    { value: 'penyakit_hati', label: 'Penyakit hati' },
    { value: 'penyakit_ginjal', label: 'Penyakit ginjal' },
    { value: 'lainnya', label: 'Lainnya' },
  ],
  labelCategory: '',
}

export const getters = {
  items: (state) => state.items,
  item: (state) => state.item,
  meta: (state) => state.meta,
  types: (state) => state.types,
  category: (state) => state.category,
  contentSections: (state) => state.contentSections,
  categoryCoaching: (state) => state.categoryCoaching,
  labelCategory: (state) => state.labelCategory,
}

export const mutations = {
  setItems(state, items) {
    state.items = items
  },
  setItem(state, item) {
    state.item = item
  },
  setTypes(state, types) {
    state.types = types
  },
  setMeta(state, meta) {
    state.meta = meta
  },
  setLabelCategory(state, data) {
    if (data.type == 'coaching_course') {
      state.labelCategory = state.categoryCoaching.find((dt) => dt.value === data.category)?.label
    } else {
      state.labelCategory = state.category.find((dt) => dt.value === data.category)?.label
    }
  },
  setCategory(state, category) {
    state.category = category
  },
  setContentType(state, data) {
    state.contentSections = data
  },
}

export const actions = {
  async listContentChallengeAdmin(ctx, params) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/admin/content-and-challenge', { params: params })
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setItems', it.data)
        ctx.commit('setMeta', it.meta)
      })
      .catch(() => {
        ctx.commit('setItems', [])
        ctx.commit('setMeta', 1)
      })
  },
  createContentChallengeAdmin(ctx, data) {
    let axios = ctx.rootGetters.axios
    return axios
      .post('/v1/admin/content-and-challenge', data)
      .then((it) => it.data.data)
      .then((it) => {
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  detailContentChallengeAdmin(ctx, id) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/admin/content-and-challenge/${id}`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setItem', it)
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  deleteContentChallengeAdmin(ctx, id) {
    let axios = ctx.rootGetters.axios
    return axios
      .delete(`/v1/admin/content-and-challenge/${id}`)
      .then((it) => it.data.data)
      .catch((err) => {
        throw err.response
      })
  },
  updateContentChallengeAdmin(ctx, data) {
    let axios = ctx.rootGetters.axios
    return axios
      .put(`/v1/admin/content-and-challenge/${data.id}`, data)
      .then((it) => it.data.data)
      .catch((err) => {
        throw err.response
      })
  },
  /**
  * @returns {Promise<{
  *     status: boolean,
  *     message: string,
  *     data: {
  *         id: string,
  *         name: string,
  *         type: string,
  *         image: string,
  *         order: number,
  *         createdAt: string,
  *         updatedAt: string,
  *     },
  *     meta: {}
  * }>}
  */
  getContentTypes(ctx) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/admin/content-types')
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setTypes', it)
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  /**
  * @returns {Promise<{
  *     status: boolean,
  *     message: string,
  *     data: {
  *         id: string,
  *         categoryName: string,
  *         contentTypeId: string,
  *         image: string,
  *         subCategory: {
  *                 "id": string,
  *                 "categoryId": string,
  *                 "subCategoryName": string,
  *                 "createdAt": string,
  *                 "updatedAt": string,
  *         }[],
  *         createdAt: string,
  *         updatedAt: string,
  *     },
  *     meta: {}
  * }>}
  */
  getContentCategory(ctx, params) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/admin/content-categories', {
        params: params,
      })
      .then((it) => {
        it.data.data
        ctx.commit('setCategory', it.data.data)
        return it.data
      })
      .catch((err) => {
        throw err.response
      })
  },
  getContentDay(ctx, params) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/admin/content-day', {
        params: params,
      })
      .then((it) => it.data.data)
      .then((it) => {
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  getContentSectionType(ctx) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/admin/content-section-type')
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setContentType', it)
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  addContentCategories(ctx, data) {
    let axios = ctx.rootGetters.axios
    return axios
      .post('/v1/admin/content-categories', data)
      .then((it) => it.data.data)
      .then((it) => {
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  updateContentCategories(ctx, data) {
    let axios = ctx.rootGetters.axios
    return axios
      .put(`/v1/admin/content-categories/${data.id}`, data)
      .then((it) => it.data.data)
      .then((it) => {
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  addContentSubCategories(ctx, data) {
    let axios = ctx.rootGetters.axios
    return axios
      .post('/v1/admin/content-sub-categories', data)
      .then((it) => it.data.data)
      .then((it) => {
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  updateContentSubCategories(ctx, data) {
    let axios = ctx.rootGetters.axios
    return axios
      .put(`/v1/admin/content-sub-categories/${data.id}`, data)
      .then((it) => it.data.data)
      .then((it) => {
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  deleteContentCategory(ctx, id) {
    let axios = ctx.rootGetters.axios
    return axios
      .delete(`/v1/admin/content-categories/${id}`)
      .then((it) => it.data.data)
      .then((it) => {
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  deleteContentSubCategory(ctx, id) {
    let axios = ctx.rootGetters.axios
    return axios
      .delete(`/v1/admin/content-sub-categories/${id}`)
      .then((it) => it.data.data)
      .then((it) => {
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
}
