export const namespaced = true

export const state = {
  couponId: '',
  formRedeem: {
    referralsGiftsId: '',
    merchantName: '',
    accountNumber: '',
    accountName: '',
    otherMerchant: '',
    productServiceId: '',
    quantity: 1,
  },
}

export const getters = {
  getCouponId: (state) => {
    return state?.couponId
  },
  formRedeem: (state) => {
    return state?.formRedeem
  },
}
export const mutations = {
  setCouponId(state, couponId) {
    state.couponId = couponId
  },
  resetCouponId(state) {
    state.couponId = ''
  },
  setFormRedeem(state, form) {
    state.formRedeem = form
  },
  resetFormRedeem(state) {
    state.formRedeem = {
      referralsGiftsId: '',
      merchantName: '',
      accountNumber: '',
      accountName: '',
      otherMerchant: '',
      productServiceId: '',
      quantity: 1,
    }
  },
}
