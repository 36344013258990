export const namespaced = true

export const state = {}

export const mutations = {}

export const actions = {
  async getFollowUpNotes(ctx, clientId) {
    const axios = ctx.rootGetters.axios

    return axios.get(`/v1/admin/clients/${clientId}/follow-up-notes`)
  },
}
