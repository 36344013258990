export const namespaced = true
export const state = {
  items: [],
  item: {},
  meta: {},
}
export const mutations = {
  setItems(state, items) {
    state.items = items
  },
  setItem(state, item) {
    state.item = item
  },
  setMeta(state, meta) {
    state.meta = meta
  },
}
export const actions = {
  async listProgramAdmin(ctx, params) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/admin/products', { params: params })
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setItems', it.data)
        ctx.commit('setMeta', it.meta)
      })
      .catch(() => {
        ctx.commit('setItems', [])
        ctx.commit('setMeta', 1)
      })
  },
  createProgramAdmin(ctx, data) {
    let axios = ctx.rootGetters.axios
    return axios
      .post('/v1/admin/products', data)
      .then((it) => it.data.data)
      .then((it) => {
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  detailProgramAdmin(ctx, id) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/admin/products/${id}`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setItem', it)
      })
      .catch((err) => {
        throw err.response
      })
  },

  deleteProgramAdmin(ctx, id) {
    let axios = ctx.rootGetters.axios
    return axios
      .delete(`/v1/admin/products/${id}`)
      .then((it) => it.data.data)
      .catch((err) => {
        throw err.response
      })
  },
  updateProgramAdmin(ctx, data) {
    let axios = ctx.rootGetters.axios
    return axios
      .put(`/v1/admin/products/${data.id}`, data)
      .then((it) => it.data.data)
      .catch((err) => {
        throw err.response
      })
  },
}
