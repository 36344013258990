import Vue from 'vue'
import { reqGetPoinUser } from '../requests/dietela-api/client/content-challenge/get-poin-user'

export const namespaced = true

export const state = () => ({
  hi: true,
  data: {},
  totalPage: 0,
  perPage: 10,
  currentPage: 1,
  rencanaMakan: [],
  rekomendasiMenu: [],
  profileGizi: null,
  asupanGizi: null,
  kondisiTubuh: null,
  saran: null,

  banners: [],
  miniDashboard: [],
  products: [],
  productsByName: [],
  product: {},
  programById: {},
  isOpen: false,
  nutritionist: {},
  kuisioner: {},
  isHavingPendingNutritionist: false, // alert pilih ahli gizi
  isHavingPendingQuisionary: false, // alert isi Formulir Gizi atau belum selesai
  isCompletedQuisionary: false, // setelah berhasil isi Formulir Gizi
  isSkipQuestionnaire: false, // program yang tidak perlu isi Formulir Gizi
  isFetchingPrograms: false,
  programChooseNutritionist: {},
  programFillQuestionnaire: {},

  successPayload: {},

  notifications: [],

  // HEADER
  customHeaderText: '',
  totalPoinUser: 0,
  showTotalPoinUser: false,
})

export const getters = {
  listClient: (s) => Object.values(s.data),
  clientWithId: (s) => (id) => s.data[id],
  banners: state => state.banners,
  miniDashboard: state => state.miniDashboard,
  products: state => state.products,
  productsByName: state => state.productsByName,
  product: state => state.product,
  programById: state => state.programById,
  isOpen: state => state.isOpen,
  nutritionist: state => state.nutritionist,
  isHavingPendingNutritionist: state => state.isHavingPendingNutritionist,
  isHavingPendingQuisionary: state => state.isHavingPendingQuisionary,
  isCompletedQuisionary: state => state.isCompletedQuisionary,
  isSkipQuestionnaire: state => state.isSkipQuestionnaire,
  isFetchingPrograms: state => state.isFetchingPrograms,
  successPayload: state => state.successPayload,
  notifications: state => state.notifications,
  programChooseNutritionist: state => state.programChooseNutritionist,
  programFillQuestionnaire: state => state.programFillQuestionnaire,

  // HEADER
  customHeaderText: (state) => state.customHeaderText,
  totalPoinUser: (state) => state.totalPoinUser,
  showTotalPoinUser: (state) => state.showTotalPoinUser,
}

export const mutations = {
  setTotalPage(state, total) {
    state.totalPage = total
  },
  setCurrentPage(state, current) {
    state.currentPage = current
  },
  setClients(state, clients) {
    for (let client of clients) {
      client.lastName = client.LastName
      // state.data[client.id] = client
      Vue.set(state.data, client.id, client)
    }
  },
  setRencanaMakan(state, rencana) {
    state.rencanaMakan = rencana
  },
  setRekomendasiMenu(state, rekomendasi) {
    state.rekomendasiMenu = rekomendasi
  },
  setProfileGizi(state, profile) {
    state.profileGizi = profile
  },
  setAsupanGizi(state, asupan) {
    state.asupanGizi = asupan
  },
  setKondisiTubuh(state, kondisi) {
    state.kondisiTubuh = kondisi
  },
  setSaran(state, saran) {
    state.saran = saran
  },
  setBanners(state, banners) {
    state.banners = banners
  },
  setMiniDashboard(state, data) {
    state.miniDashboard = data
  },
  setProducts(state, products) {
    state.products = products
  },
  setProductsByName(state, products) {
    state.productsByName = products
  },
  setProduct(state, product) {
    state.product = product
  },
  setProgramById(state, program) {
    state.programById = program
  },
  setToggleModal(state, status) {
    state.isOpen = status
  },
  setNutritionist(state, nutritionist) {
    state.nutritionist = nutritionist
  },
  setKuisioner(data, kuisioner) {
    state.kuisioner = kuisioner
  },
  setPendingNutritionist(state, status) {
    state.isHavingPendingNutritionist = status
  },
  setPendingQuisionary(state, status) {
    state.isHavingPendingQuisionary = status
  },
  setCompletedQuisionary(state, status) {
    state.isCompletedQuisionary = status
  },
  setSkipQuestionnaire(state, status) {
    state.isSkipQuestionnaire = status
  },
  setFetchPrograms(state, status) {
    state.isFetchingPrograms = status
  },
  setSuccessPayload(state, data) {
    state.successPayload = data
  },
  setNotifications(state, notifications) {
    state.notifications = notifications
  },
  setProgramChooseNutritionist(state, data) {
    state.programChooseNutritionist = data
  },
  setProgramFillQuestionnaire(state, data) {
    state.programFillQuestionnaire = data
  },

  // HEADER
  setCustomHeaderText(state, text) {
    state.customHeaderText = text
  },
  setTotalPoinUser(state, total) {
    state.totalPoinUser = total
  },
  setShowTotalPoinUser(state, status) {
    state.showTotalPoinUser = status
  },
}

export const actions = {
  getClientProfileById(ctx, clientId) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/nutritionist/clients/${clientId}`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setClients', [it])
        return it
      })
  },
  /**
   * @param {string} programId
   *
   * @returns { Promise<{
   *     status: boolean,
   *     message: string,
   *     data: {
   *     "program": {
   *         "id": string,
   *         "clientId": string,
   *         "nutritionistId": string,
   *         "name": string,
   *         "programService": string,
   *         "startAt": string,
   *         "endAt": string,
   *         "duration": number,
   *         "counterDay": number
   *     },
   *     "user": {
   *         "fullName": String,
   *         "id": String,
   *         "firstName": String,
   *         "lastName": String,
   *         "photoUrl": String
   *     },
   *     "nutritionist": {
   *         "fullName": String,
   *         "rating": number,
   *         "id": String,
   *         "firstName": String,
   *         "lastName": String,
   *         "serviceHour": String,
   *         "specialization": null,
   *         "education": {
   *                 "level": string,
   *                 "title": string,
   *                 "university": string,
   *                 "otherUniversity": null
   *          }[],
   *         "str": string,
   *         "problemHandled": null,
   *         "clientAgeHandled": string,
   *         "workExperiences": [],
   *         "languages": string,
   *         "photoUrl": null
   *     }
   *    },
   *     meta: {}
   * }> }
   */
  getNutritionistDetailProgram(ctx, programId) {
    return ctx.rootGetters.axios
      .get(`/v1/nutritionist/detail-program/${programId}`)
      .then((it) => it.data)
  },
  /**
   * @param {string} nutritionistId
   *
   * @returns { Promise<{
   *     status: boolean,
   *     message: string,
   *     data: {
   *       "rating": number,
   *       "id": string,
   *       "photoUrl": string,
   *       "quota": number,
   *       "nClient": number,
   *       "firstName": string,
   *       "lastName": string,
   *       "str": string,
   *       "specialization": null,
   *       "problemHandled": null,
   *       "clientAgeHandled": string,
   *       "languages": string,
   *       "education": {
   *          "level": string,
   *          "title": string,
   *          "university": string
   *       }[],
   *       "workExperiences": [],
   *       "serviceHour": string,
   *       "activeHour": {
   *          "endHour": string,
   *          "startHour": string
   *       }[],
   *       "serviceTime": {
   *          "title": string,
   *          "value": string
   *       }[]
   *     },
   *     meta: {}
   * }> }
   */
  getNutritionistDetailProgramNutritionist(ctx, nutritionistId) {
    return ctx.rootGetters.axios
      .get(`/v1/nutritionist/detail-program/nutritionist/${nutritionistId}`)
      .then((it) => it.data)
  },
  /**
   * @param {string} programId
   *
   * @returns { Promise<{
   *     status: boolean,
   *     message: string,
   *     data: {
   *        id: String,
   *        title: String,
   *        date: String,
   *        status: String
   *     },
   *     meta: {}
   * }> }
   */
  getNutritionistDetailProgramMealPlan(ctx, programId) {
    return ctx.rootGetters.axios
      .get(`/v1/nutritionist/detail-program/${programId}/meal-plan`)
      .then((it) => it.data)
  },
  /**
   * @param {string} programId
   *
   * @returns { Promise<{
   *     status: boolean,
   *     message: string,
   *     data: {
   *        id: String,
   *        title: String,
   *        date: String,
   *        status: String
   *     },
   *     meta: {}
   * }> }
   */
  getNutritionistDetailProgramAdime(ctx, programId) {
    return ctx.rootGetters.axios
      .get(`/v1/nutritionist/detail-program/${programId}/adime`)
      .then((it) => it.data)
  },
  /**
   * @param {string} programId
   *
   * @returns { Promise<{
   *     status: boolean,
   *     message: string,
   *     data: {
   *        id: String,
   *        title: String,
   *        date: String,
   *        status: String
   *     },
   *     meta: {}
   * }> }
   */
  getNutritionistDetailProgramFollowUp(ctx, programId) {
    return ctx.rootGetters.axios
      .get(`/v1/nutritionist/detail-program/${programId}/follow-up`)
      .then((it) => it.data)
  },
  /**
   * @param {string} programId
   *
   * @returns { Promise<{
   *     status: boolean,
   *     message: string,
   *     data: {
   *        id: String,
   *        title: String,
   *        date: String,
   *        status: String
   *     },
   *     meta: {}
   * }> }
   */
  getNutritionistDetailProgramProgressTracker(ctx, programId) {
    return ctx.rootGetters.axios
      .get(`/v1/nutritionist/detail-program/${programId}/progress-tracker`)
      .then((it) => it.data)
  },
  getClientById(ctx, clientId) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/nutritionist/diet-data/${clientId}`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setClients', [it])
      })
  },
  listClient(ctx, props) {
    let axios = ctx.rootGetters.axios
    let perPage = ctx.state.perPage
    let page = props?.page ?? 1
    let query = props?.query

    let params = new URLSearchParams()
    params.set('perpage', perPage)
    params.set('page', page)
    if (query != null) params.set('query', '')

    return axios
      .get(`/v1/nutritionist/clients?${params.toString()}`)
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setClients', it.data)
        ctx.commit('setTotalPage', it.meta.total)
        ctx.commit('setCurrentPage', it.meta.page)
      })
  },
  getRencanaMakan(ctx, programId) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/clients/meal-plans/${programId}`)
      .then((it) => it.data)
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setRencanaMakan', it)
        return it
      })
  },
  getRekomendasiMenu(ctx, { programId, day }) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/clients/meals-recommendation/${programId}/${day}`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setRekomendasiMenu', it)
        return it
      })
  },
  getProfileGizi(ctx, programId) {
    let axios = ctx.rootGetters.axios
    let url = programId == null ? '/v1/clients/nutrition-profile' : `/v1/clients/nutrition-profile/${programId}`
    return axios
      .get(url)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setProfileGizi', it)
      })
  },
  getAsupanGizi(ctx, programId) {
    let axios = ctx.rootGetters.axios
    let url = programId == null ? '/v1/clients/nutrition-intake' : `/v1/clients/nutrition-intake/${programId}`
    return axios
      .get(url)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setAsupanGizi', it)
      })
  },
  getKondisiTubuh(ctx, programId) {
    let axios = ctx.rootGetters.axios
    let url =
      programId == null ? '/v1/clients/body-proportion-lifestyle' : `/v1/clients/body-proportion-lifestyle/${programId}`

    return axios
      .get(url)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setKondisiTubuh', it)
      })
  },
  getSaran(ctx, programId) {
    let axios = ctx.rootGetters.axios
    let url =
      programId == null
        ? '/v1/clients/nutrition-lifestyle-advice'
        : `/v1/clients/nutrition-lifestyle-advice/${programId}`

    return axios
      .get(url)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setSaran', it)
      })
      .catch(() => {})
  },
  getBanners(ctx) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/general/banner')
      .then((it) => it.data)
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setBanners', it)
      })
  },
  getMiniDashboard(ctx) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/general/mini-dashboard')
      .then((it) => it.data)
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setMiniDashboard', it)
      })
  },
  getProducts(ctx) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/general/products')
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setProducts', it.data)
        return it
      })
  },
  getProductByName(ctx, name) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/general/products-name/${name}`)
      .then((it) => it.data)
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setProductsByName', it)
      })
  },
  getProductById(ctx, id) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/general/products/${id}`)
      .then((it) => it.data)
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setProduct', it)
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  getProductByDuration(ctx, { productId, duration }) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/general/products/${productId}?duration=${duration}`)
      .then((it) => it.data)
      .then((it) => {
        return it
      })
  },
  getProgramById(ctx, id) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/clients/programs/${id}`)
      .then((it) => it.data)
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setProgramById', it)
        return it
      })
  },
  onToggleModal(ctx, status) {
    ctx.commit('setToggleModal', status)
  },
  addToCart(ctx, product) {
    let axios = ctx.rootGetters.axios
    return axios
      .post('/v1/clients/carts', product)
      .then((r) => r.data.data)
      .then((r) => {
        return r
      })
  },
  async getKuisioner(ctx, programId) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/clients/questionnaire/${programId}`)
      .then((it) => it.data.data)
      .then((data) => {
        ctx.commit('setKuisioner', data)
        return data
      })
  },
  getNutritionistById(ctx, nutritionistId) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/clients/nutritionist/${nutritionistId}`)
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setNutritionist', it.data)
        return it
      })
  },
  updateSuccessPayload(ctx, data) {
    ctx.commit('setSuccessPayload', data)
  },
  async loadNotifications(ctx) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/users/notifications')
      .then((it) => it.data.data)
      .then((it) => {
        // ctx.commit('setNotifications', it)
        return it
      })
      .catch((_) => {})
  },
  chooseNutritionist(ctx, data) {
    let axios = ctx.rootGetters.axios
    return axios
      .post(`/v1/clients/programs/${data.programId}/choose-nutritionist`, data.nutritionist)
      .then((it) => it.data.data)
      .then((it) => {
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },
  readNotification(ctx, notificationId) {
    let axios = ctx.rootGetters.axios
    return axios
      .put(`v1/users/notifications/${notificationId}/read`)
      .then((it) => it.data.data)
      .catch((err) => {
        throw err.response
      })
  },
  checkActiveProgram(ctx) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('v1/clients/programs-active')
      .then((v) => {
        if (!v.data.status) throw new Error('error')
        return v.data.data
      })
      .catch((err) => {
        throw err.response
      })
  },
  fetchHistoryTransactions(ctx, params = {}) {
    let axios = ctx.rootGetters.axios
    const filterTransaction = params.filter_transaction === 'ALL' ? undefined : params.filter_transaction
    return axios
      .get('v1/clients/list-transaction', {
        params: {
          offset: params.offset,
          limit: params.limit,
          filter_transaction: filterTransaction,
        },
      })
      .then((v) => {
        if (!v.data.status) throw new Error('error')
        return v.data.data
      })
      .catch((err) => {
        throw err.response
      })
  },
  fetchDetailHistoryTransaction(ctx, transactionId) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`v1/clients/transaction-id/${transactionId}`)
      .then((v) => {
        if (!v.data.status) throw new Error('error')
        return v.data.data
      })
      .catch((err) => {
        throw err.response
      })
  },
  /**
   * @param {Object} data
   * @param {string} data.productServiceId
   * @param {number} data.qty
   * @param {string | undefined} [data.cartId]
   * @param {string | undefined} [data.couponId]
   *
   * @returns { Promise<{
   *     status: boolean,
   *     message: string,
   *     data: {
   *         id: string,
   *         isSentReminder: number,
   *         cartId: string,
   *         orderId: string,
   *         clientId:string,
   *         productServiceId: string,
   *         total: number,
   *         subTotal: number,
   *         totalDiscount: number,
   *         duration: number,
   *         invoiceNumber: string,
   *         transactionStatus: ("pending"),
   *         status: ("pending"),
   *         couponId: null,
   *         couponName: null,
   *         productPrice: number,
   *         productName: string,
   *         productServiceName: string,
   *         productPhoto: string,
   *         quantity: number,
   *         updatedAt: string,
   *         createdAt: string
   *     },
   *     meta: {}
   * }> }
   */
  async reqCheckout(ctx, data = {}) {
    try {
      let axios = ctx.rootGetters.axios
      return await axios
        .post('/v1/clients/transaction', {
          cartId: data.cartId || null,
          productServiceId: data.productServiceId || null,
          couponId: data.couponId || null,
          qty: data.qty || null,
        })
        .then((it) => it.data)
    } catch (e) {
      throw new Error(e?.response?.data?.message || 'error')
    }
  },
  /**
   * @param {Object} data
   * @param {string} data.couponCode
   * @param {string} data.productServiceId
   *
   * @returns { Promise<{
   *     status: boolean,
   *     message: ("coupon not found"),
   *     data: {
   *         id: string,
   *         type: ('percent'),
   *         totalDiscount: number,
   *     },
   *     meta: {}
   * }> }
   */
  async reqCheckCoupon(ctx, data = {}) {
    try {
      let axios = ctx.rootGetters.axios
      return await axios
        .get(`/v1/clients/coupons/${data.couponCode || 'xxx'}/${data.productServiceId || 'xxx'}`)
        .then((it) => it.data)
    } catch (e) {
      throw new Error(e?.response?.data?.message || 'error')
    }
  },

  /**
   * @returns { Promise<{
   *     status: boolean,
   *     message: string,
   *     data: {
   *         id: string,
   *         label: string,
   *         description: string,
   *         value: number
   *     }[],
   *     meta: {}
   * }> }
   */
  async reqProgramsExtendHeader(ctx) {
    try {
      let axios = ctx.rootGetters.axios
      return await axios.get('/v1/clients/programs-extend/header').then((it) => it.data)
    } catch (e) {
      throw new Error(e?.response?.data?.message || 'error')
    }
  },

  /**
   * @returns { Promise<{
   *     status: boolean,
   *     message: string,
   *     data: {
   *         id: string,
   *         productId: string,
   *         programsService: string,
   *         duration: number,
   *         price: number,
   *         discountedPrice: number,
   *         serviceTarget: string[],
   *         order: number
   *     }[],
   *     meta: {}
   * }> }
   */
  async reqProgramsExtendList(ctx) {
    try {
      let axios = ctx.rootGetters.axios
      return await axios.get('/v1/clients/programs-extend/list').then((it) => it.data)
    } catch (e) {
      throw new Error(e?.response?.data?.message || 'error')
    }
  },

  /**
   * @returns { Promise<{
   *     status: boolean,
   *     message: string,
   *     data: {
   *         program: {
   *             counterDay: number,
   *             duration: number,
   *             endAt: string,
   *             id: string,
   *             name: string,
   *             programService: string,
   *             startAt: string,
   *             status: string,
   *             product: {
   *                 photoUrl: string,
   *                 productCategories: {
   *                     name: string,
   *                 }
   *             },
   *         },
   *         user: {
   *             email: string,
   *             firstName: string,
   *             fullName: string,
   *             id: string,
   *             lastName: string,
   *             photoUrl: string,
   *         }
   *     },
   *     meta: {}
   * }> }
   */
  async getClientDetail(ctx, clientId) {
    try {
      let axios = ctx.rootGetters.axios
      return await axios.get(`/v1/nutritionist/detail-client/${clientId}`).then((it) => it.data)
    } catch (e) {
      throw e.response
    }
  },

  /**
   * @returns { Promise<{
   *     status: boolean,
   *     message: string,
   *     data: {
   *         id: string,
   *         clientId: string,
   *         couponName: string,
   *         createdAt: string,
   *         duration: number,
   *         endAt: string,
   *         isChooseNutritionist: number,
   *         isLatest: boolean,
   *         isSkipQuestionnaire: number,
   *         name: string,
   *         nutritionistId: string,
   *         orderId: string,
   *         productId: string,
   *         productPrice: number,
   *         productServiceId: string,
   *         programService: string,
   *         isChooseNutritionist: string,
   *         startAt: string
   *         status: string
   *     }[],
   *     meta: {}
   * }> }
   */
  async getClientPrograms(ctx, clientId) {
    try {
      let axios = ctx.rootGetters.axios
      return await axios.get(`/v1/nutritionist/detail-client/${clientId}/all-programs`).then((it) => it.data)
    } catch (e) {
      throw e.response
    }
  },

  /**
   * @returns { Promise<{
   *     status: boolean,
   *     message: string,
   *     data: {
   *         id: string,
   *         clientId: string,
   *         completedAt: string,
   *         createdAt: string,
   *         isAgreeForResearch: number,
   *         isAgreeInformedConsent: number,
   *         isDraft: boolean,
   *         program: Program[],
   *         programId: string,
   *         updatedAt: string,
   *     }[],
   *     meta: {}
   * }> }
   */
  async getClientQuestionnaire(ctx, clientId) {
    try {
      let axios = ctx.rootGetters.axios
      return await axios.get(`/v1/nutritionist/detail-client/${clientId}/all-questionnaire`).then((it) => it.data)
    } catch (e) {
      throw e.response
    }
  },

  /**
  * @returns { Promise<{
  *     status: boolean,
  *     message: string,
  *     data: {
  *         couponName: null | string,
  *         invoiceNumber: string,
  *         productName: string,
  *         productServiceName: string,
  *         subTotal: number,
  *         totalDiscount: number,
  *         transactionStatus: string,
  *         transactionTime: string,
  *         paymentMethod: {
  *             paymentMethodName: string,
  *         },
  *     }[],
  *     meta: {}
  * }> }
  */
  async getClientTransaction(ctx, clientId) {
    try {
      let axios = ctx.rootGetters.axios
      return await axios.get(`/v1/admin/detail-client/${clientId}/all-payment`).then((it) => it.data)
    } catch (e) {
      throw e.response
    }  
  },

  // HEADER
  async getTotalUserPoin(ctx) {
    return reqGetPoinUser(ctx.rootGetters.axios)
      .then((it) => ctx.commit('setTotalPoinUser', it.data?.data?.totalPoint || 0))
  },
}
