/** @typedef {Object} TReturn
 * @property {Boolean} status
 * @property {"OK"} message
 */

/**
 * function get
 * @param {any} axios
 * @returns {Promise<TReturn & {
 *   data: {
 *     referralData: {
 *       id: String,
 *       clientId: String,
 *       code: String,
 *       createdAt: String | Date,
 *       clientIdsUse: Array<String>,
 *     },
 *     bannerData: {
 *       bannerUrl: String,
 *     },
 *     howToShare: Array<{item: String}>
 *     ReferralsMenu: {
 *       id: String,
 *       description: String,
 *       image: String,
 *     },
 *     totalClientUse: Number,
 *     infoClientUse: Array<{email: String}>,
 *   }}
 */
const get = async(axios) => {
  return axios.get('/v1/referral')
}

const faqs = async(axios) => {
  return axios.get('/v1/referral/faqs')
}

const poin = async(axios) => {
  return axios.get('/v1/referral/poin')
}

// https://gitlab.com/web-apps-dietela/backend/-/merge_requests/365
const gifts = async(axios, { page, perpage, q, category }) => {
  return axios.get('/v1/referral/gifts', {
    params: {
      page: page || 1,
      perpage: perpage || 10,
      q: q || null, // search 
      category: category || null, // filter
    },
  })
}

const createGifts = async(
  axios,
  {
    type,
    name,
    price,
    quota,
    description,
    termCondition,
    category,
    image,
    startAt,
    endAt,
    duration,
    productServiceGroupId,
    productIds,
    isMultipleRedeem,
  }) => {
  let payload = {}
  switch (type) {
    case 'PROGRAM':
      payload = {
        name: name,
        price: price,
        quota: quota,
        description: description,
        termCondition: termCondition,
        category: category,
        image: image,
        startAt: startAt,
        endAt: endAt,
        isMultipleRedeem: isMultipleRedeem,
        //
        duration: duration,
        productServiceGroupId: productServiceGroupId,
        productIds: productIds,
      }
      break
    case 'EWALLET':
      payload = {
        name: name,
        price: price,
        quota: quota,
        description: description,
        termCondition: termCondition,
        category: category,
        image: image,
        startAt: startAt,
        endAt: endAt,
        isMultipleRedeem: isMultipleRedeem,
      }
      break
    default:
      throw new Error('')
  }

  return axios.post(
    '/v1/referral/gifts',
    payload,
  )
}

// https://gitlab.com/web-apps-dietela/backend/-/merge_requests/365
const poinHistory = async(axios, { page, perpage, q, filter, clientId }) => {
  return axios.get('/v1/referral/poin-history', {
    params: {
      page: page || 1,
      perpage: perpage || 10,
      q: q || null, // search 
      filter: filter || null, // filter
      clientId: clientId || null,
    },
  })
}

/**
 * function detailClientUse
 * @param {any} axios
 * @returns {Promise<TReturn & {
 *   data: Array<{
 *      email: String,
 *      point: Number,
 *      date: String | Date
 *      photoUrl?: String
 *   }>
 * }>}
 */
const detailClientUse = async(axios) => {
  return axios.get('/v1/referral/detail-client-use')
}

/**
 * function extraPointAchievement
 * @param {any} axios
 * @returns {Promise<TReturn & {
 *   data: Array<{
 *      totalPoin: Number
 *      extraPoin: Number
 *      label: String,
 *      order: Number
 *      totalPoinAccuired: Number
 *      isAchieved: Boolean
 *   }>
 * }>}
 */
const extraPointAchievement = async(axios) => {
  return axios.get('/v1/referral/extra-point-achievement')
}

/**
 * function termsAndConditions
 * @param {any} axios
 * @returns {Promise<TReturn & {
 *   data: Array<{
 *      value: String,
 *      order: Number
 *   }>
 * }>}
 */
const termsAndConditions = async(axios) => {
  return axios.get('/v1/referral/terms-and-conditions')
}

/**
 * function detailClientReferral
 * @param {any} axios
 * @param {number} page
 * @param {number} perpage
 * @param {string} clientId
 * @returns {Promise<TReturn & {
 *   data: {
 *     clientsUse: {
 *        count: Number,           
 *        rows: Array<{
 *          date: String,
 *          dataString: String,
 *          email: String,
 *          photoUrl: String | null,
 *          point: String,
 *        }>,
 *     }
 *     code: String, 
 *     email: String, 
 *     finalPoin: Number, 
 *     fistName: String, 
 *     lastName: String, 
 *      photoUrl: String | null, 
 *   }
 * }>}
*/
const detailClientReferral = async(axios, { page, perpage, clientId }) => {
  return axios.get('/v1/referral/detail-client-referral', {
    params: {
      page: page || 1,
      perpage: perpage || 10,
      clientId: clientId || null,
    },
  })
}

/**
 * function detailGift
 * @param {any} axios
 * @param {string} giftId
 * @returns {Promise<TReturn & {
*   data: Array<{
  *      value: String,
  *      order: Number
  *   }>
  * }>}
  */
const detailGift = async(axios, { giftId }) => {
  return axios.get(`/v1/referral/gifts/${giftId}`)
}

const editGift = async(
  axios,
  {
    giftId,
    type,
    name,
    price,
    quota,
    description,
    termCondition,
    category,
    image,
    startAt,
    endAt,
    duration,
    productServiceGroupId,
    productIds,
    isActive,
    isMultipleRedeem,
  }) => {
  let payload = {}
  switch (type) {
    case 'PROGRAM':
      payload = {
        name: name,
        price: price,
        quota: quota,
        description: description,
        termCondition: termCondition,
        category: category,
        image: image,
        startAt: startAt,
        endAt: endAt,
        isMultipleRedeem: isMultipleRedeem,
        //
        duration: duration,
        productServiceGroupId: productServiceGroupId,
        productIds: productIds,
      }
      break
    case 'EWALLET':
      payload = {
        name: name,
        price: price,
        quota: quota,
        description: description,
        termCondition: termCondition,
        category: category,
        image: image,
        startAt: startAt,
        endAt: endAt,
        isMultipleRedeem: isMultipleRedeem,
      }
      break
    case 'CHANGE_STATUS':
      payload = {
        isActive: isActive,
      }
      break
    default:
      throw new Error('')
  }

  return axios.put(
    `/v1/referral/gifts/${giftId}`,
    payload,
  )
}

const deleteGift = async(axios, { giftId }) => {
  return axios.delete(`/v1/referral/gifts/${giftId}`)
}

/**
 * function requestRedeem
 * @param {any} axios
 * @param {any} payload
 * @returns {Promise<TReturn & {
*   data: Array<{
  *      value: String,
  *      order: Number
  *   }>
  * }>}
  */
const requestRedeem = async(axios, payload) => {
  return axios.post('/v1/referral/redeem', payload)
}

/**
  * function detailRedeem
  * @param {any} axios
  * @param {string} redeemId
  * @returns {Promise<TReturn & {
  *   data: Array<{
  *      value: String,
  *      order: Number
  *   }>
  * }>}
*/
const detailRedeem = async(axios, { redeemId }) => {
  return axios.get(`/v1/referral/redeem/${redeemId}`)
}

/**
 * function detailRedeemRefund
 * @param {any} axios
 * @param {string} redeemId
 */
const detailRedeemRefund = async(axios, { redeemId }) => {
  return axios.get(`/v1/referral/redeem/${redeemId}/refund`)
}

/**
  * function redeem
  * @param {any} axios
  * @returns {Promise<TReturn & {
  *   data: {
  *     clientsUse: {
  *        count: Number,           
  *        rows: Array<{
  *          date: String,
  *          dataString: String,
  *          email: String,
  *          photoUrl: String | null,
  *          point: String,
  *        }>,
  *     }
  *     code: String, 
  *     email: String, 
  *     finalPoin: Number, 
  *     fistName: String, 
  *     lastName: String, 
  *   }
  * }>}
 */
const redeem = async(axios, { page, perpage, clientId, q }) => {
  return axios.get('/v1/referral/redeem', {
    params: {
      page: page || 1,
      perpage: perpage || 10,
      clientId: clientId || null,
      q: q || null,
    },
  })
}

const giftFormFilter = async(axios) => {
  return axios.get('/v1/referral/gift-form-filter')
}

const giftFormProduct = async(axios, { duration, productServiceGroupId }) => {
  return axios.get('/v1/referral/gift-form-product', {
    params: {
      duration,
      productServiceGroupId,
    },
  })
}

const uploadFile = async(axios, formData) => {
  return axios.post('/v1/referral/upload-file', formData)
}

const resetPoin = async(axios, { clientId, note }) => {
  return axios.post('/v1/referral/reset', {
    clientId,
    note,
  })
}

const schema = async(axios, { page, perpage }) => {
  return axios.get('/v1/referral/schema', {
    params: {
      page: page || 1,
      perpage: perpage || 10,
    },
  })
}

const detailSchema = async(axios, { schemaId }) => {
  return axios.get(`/v1/referral/schema/${schemaId}`)
}

const createSchema = async(
  axios,
  // eslint-disable-next-line
  { schemaId, ...payload }) => {
  return axios.post('/v1/referral/schema', payload)
}

const editSchema = async(
  axios,
  payload) => {
  return axios.put(`/v1/referral/schema/${payload.schemaId}`, payload)
}

const getSchemaOptionPrograms = async(
  axios,
  {
    slug, // "body-goals"|"body-for-baby"|"clinicare"|"konsultasi"[]!
  }) => {
  return axios.get('/v1/referral/schema/program', {
    params: {
      slug: JSON.stringify(slug),
    },
  })
}
const editRedeem = async(
  axios,
  {
    redeemId, // string!
    status, // enum! success | failed
    note, // string?
  }) => {
  return axios.put(`/v1/referral/redeem/${redeemId}`, {
    status,
    note,
  })
}

const updateSchemaStatus = async(axios, { schemaId, status }) => {
  return axios.put(`/v1/referral/schema/${schemaId}/change-status?status=${status}`)
}

const deleteSchema = async(axios, { schemaId }) => {
  return axios.delete(`/v1/referral/schema/${schemaId}`)
}

export const reqReferral = {
  get,
  faqs,
  poin,
  gifts,
  poinHistory,
  detailClientUse,
  extraPointAchievement,
  termsAndConditions,
  detailClientReferral,
  detailGift,
  requestRedeem,
  detailRedeem,
  detailRedeemRefund,
  editRedeem,
  redeem,
  createGifts,
  editGift,
  deleteGift,
  giftFormFilter,
  giftFormProduct,
  uploadFile,
  resetPoin,
  schema,
  detailSchema,
  createSchema,
  editSchema,
  getSchemaOptionPrograms,
  updateSchemaStatus,
  deleteSchema,
}