export const namespaced = true

export const state = {
  dataProfile: '',
}

export const getters = {
  fullName(s) {
    return `${s.dataProfile.firstName} ${s.dataProfile.lastName}`
  },
  dataProfile: (state) => state.dataProfile,
}

export const mutations = {
  setProfile(state, profile) {
    state.dataProfile = profile
  },
}

export const actions = {
  getProfile(ctx) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/nutritionist')
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setProfile', it)
        return it
      })
  },
  updateProfile(ctx, { profile }) {
    if (profile.length === 0) return Promise.resolve()

    let axios = ctx.rootGetters.axios

    return axios
      .put('/v1/nutritionist', profile)
      .then((it) => it.data.message)
      .then(() => {
        ctx.commit('auth/setUser', {
          ...ctx.rootState.auth.user,
          firstName: profile.firstName,
          lastName: profile.lastName,
          photoUrl: profile.photoUrl,
        }, { root: true })
      })
      .catch((err) => {
        throw err.response
      })
  },
  getProfileClient(ctx) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/clients')
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setProfile', it)
        return it
      })
  },
  getProfileClientAjaxOnly(ctx) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/clients')
      .then((it) => it.data.data)
  },
  updateProfileClient(ctx, { profile }) {
    if (profile.length === 0) return Promise.resolve()

    let axios = ctx.rootGetters.axios

    return axios
      .put('/v1/clients', profile)
      .then((it) => it.data)
      .then((it) => {
        if (it.status) {
          ctx.commit('auth/setUser', {
            ...ctx.rootState.auth.user,
            firstName: profile.firstName ? profile.firstName : ctx.rootState.auth.user.firstName,
            lastName: profile.lastName ? profile.lastName : ctx.rootState.auth.user.lastName,
            photoUrl: profile.photoUrl ? profile.photoUrl : ctx.rootState.auth.user.photoUrl,
          }, { root: true })
  
          ctx.commit('profile/setProfile', {
            ...ctx.rootState.profile.dataProfile,
            ...profile,
          }, { root: true })
        }
        return it
      })
      .catch((err) => {
        throw err.response
      })
  },

  // superadmin
  getProfileSuperadmin(ctx) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/super-admin')
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setProfile', it)
      })
  },
  updateProfileSuperadmin(ctx, { profile }) {
    if (profile.length === 0) return Promise.resolve()

    let axios = ctx.rootGetters.axios

    return axios
      .put('/v1/super-admin', profile)
      .then((it) => it.data.message)
      .then(() => {
        ctx.commit('auth/setUser', {
          ...ctx.rootState.auth.user,
          firstName: profile.firstName,
          lastName: profile.lastName,
          photoUrl: profile.photoUrl,
        }, { root: true })
      })
      .catch((err) => {
        throw err.response
      })
  },

  // admin
  getProfileAdmin(ctx) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/admin')
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setProfile', it)
      })
  },
  updateProfileAdmin(ctx, { profile }) {
    if (profile.length === 0) return Promise.resolve()

    let axios = ctx.rootGetters.axios

    return axios
      .put('/v1/admin', profile)
      .then((it) => it.data.message)
      .then(() => {
        ctx.commit('auth/setUser', {
          ...ctx.rootState.auth.user,
          firstName: profile.firstName,
          lastName: profile.lastName,
          photoUrl: profile.photoUrl,
        }, { root: true })
      })
      .catch((err) => {
        throw err.response
      })
  },
}
